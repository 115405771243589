import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import { GET } from "../../api/ApiService";
import EncryptionHelper from "../../shared/EncryptionHelper";
import moment from "moment";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import { toast } from "react-toastify";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    // if (page > 1) {
    onPageChange(event, page - 1);
    // }
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: "2.5rem" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </div>
  );
}
const Audit = () => {
  const [auditData, setAuditData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const fetchData = () => {
    setLoading(true);

    GET(`case/case-audit-log?page=${page + 1}&limit=${rowsPerPage}`)
      .then((resp) => {
        const encryptedData = resp?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        console.log("decryptedData of audit log ==> ", decryptedData);
        setAuditData(decryptedData?.data);
        setTotalRows(decryptedData?.total);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box>
      <MainHeader headingText={"Audit log"} />
      <Stack
        gap={3}
        p={4}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        {loading ? (
          <Stack justifyContent={"center"} alignItems={"center"} height={400}>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Case Name</TableCell>
                    <TableCell>Case Number</TableCell>
                    <TableCell>Modified By</TableCell>
                    <TableCell>Column Name</TableCell>
                    <TableCell>New Value</TableCell>
                    <TableCell>Previous Value</TableCell>
                    <TableCell>Table Name</TableCell>
                    <TableCell>Time Stamp</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {auditData?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.CaseName}</TableCell>
                      <TableCell>{item.CaseNumber}</TableCell>
                      <TableCell>{item.ModifiedByUserName}</TableCell>
                      <TableCell>{item.column_name}</TableCell>
                      <TableCell>{item.new_value}</TableCell>
                      <TableCell>{item.previous_value}</TableCell>
                      <TableCell>{item.table_name}</TableCell>
                      <TableCell>
                        {item.timestamp
                          ? moment(item.timestamp).format("LL")
                          : "null"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 100, 500]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </>
        )}
      </Stack>
    </Box>
  );
};

export default Audit;
