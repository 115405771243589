import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import TextInput from "../../TextInput";
import CheckboxInput from "../../CheckboxInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import SignatureCanvas from "react-signature-canvas";
import ButtonCommon from "../../ButtonCommon";
import drawerLogo from "../../../assets/logo.png";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
// import AutocompleteInput from "../../AutocompleteInput";

import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { POST } from "../../../api/ApiService";
import { toast } from "react-toastify";
import CaseSearchDropdown from "../../CaseSearchDropdown";

const options = {
  filename: "visiting-form.pdf",
  // default is `save`
  method: "open",
  // default is Resolution.MEDIUM = 3, which should be enough, higher values
  // increases the image quality but also the size of the PDF, so be careful
  // using values higher than 10 when having multiple pages generated, it
  // might cause the page to crash or hang.
  resolution: Resolution.LOW,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: Margin.SMALL,
    // default is 'A4'
    format: "letter",
    // orientation: "landscape",
    // orientation: "portrait",
  },
  canvas: {
    // default is 'image/jpeg' for better size performance
    // mimeType: "image/png",
    qualityRatio: 1,
  },
  // Customize any value passed to the jsPDF instance and html2canvas
  // function. You probably will not need this and things can break,
  // so use with caution.
  overrides: {
    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    pdf: {
      compress: true,
    },
    // see https://html2canvas.hertzen.com/configuration for more options
    canvas: {
      useCORS: true,
    },
  },
};

// Reusable component for text input with error handling
const FormTextInput = ({ name, label, formik }) => (
  <Stack direction={"row"} alignItems={"center"}>
    <Typography>{label}:</Typography>
    <Box sx={{ width: "400px" }}>
      <TextInput
        name={name}
        variant="standard"
        onChangeValue={formik.handleChange}
        onBlurValue={formik.handleBlur}
        value={formik.values[name]}
        startIcon={false}
      />
      {formik.touched[name] && formik.errors[name] && (
        <div style={{ fontSize: "smaller", color: "red", textAlign: "left" }}>
          {formik.errors[name]}
        </div>
      )}
    </Box>
  </Stack>
);

// Reusable component for checkbox input with error handling
const FormCheckboxInput = ({ name, label, formik }) => (
  <Stack direction={"row"} alignItems={"center"}>
    <CheckboxInput
      name={name}
      onChangeValue={formik.handleChange}
      onBlurValue={formik.handleBlur}
      value={formik.values[name]}
      label={label}
    />
    {formik.touched[name] && formik.errors[name] && (
      <div style={{ fontSize: "smaller", color: "red", textAlign: "left" }}>
        {formik.errors[name]}
      </div>
    )}
  </Stack>
);

const PolicyCheckbox = ({ name, label, formik }) => (
  <Box mb={4}>
    <Typography dangerouslySetInnerHTML={{ __html: label }} />
    <FormCheckboxInput
      name={name}
      label={`I've read and agree to the terms outlined in section ${
        name.split("y")[1]
      }`}
      formik={formik}
    />
  </Box>
);

const VisitingForm = () => {
  // const caseDropdown = useSelector((state) => state.allcaseReducer.allcase);
  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const [clientSign, setClientSign] = useState();
  const [workerSign, setWorkerSign] = useState();
  const [attachTocase, setAttachTocase] = useState(false);
  const [resetDropdown, setResetDropdown] = useState(false);

  const clientSigCanvas = useRef({});
  const socialSigCanvas = useRef({});
  const contentRef = useRef(null);

  useEffect(() => {
    if (resetDropdown) {
      setResetDropdown(false);
    }
  }, [resetDropdown]);

  const clearClientSignature = () => {
    clientSigCanvas.current.clear();
    setClientSign(null);
  };

  const clearSocialSignature = () => {
    socialSigCanvas.current.clear();
    setWorkerSign(null);
  };

  const saveClientSignature = () => {
    const signatureImage = clientSigCanvas.current.toDataURL();
    // Now you can send `signatureImage` to your API
    setClientSign(signatureImage);
  };

  const saveSocialSignature = () => {
    const signatureImage = socialSigCanvas.current.toDataURL();
    // Now you can send `signatureImage` to your API
    setWorkerSign(signatureImage);
  };

  const handleGeneratePDF = async (caseID) => {
    const content = contentRef.current;
    if (!content) {
      console.error("Content reference is null");
      return;
    }

    try {
      // Get the sections that need to start on new pages
      const sections = content.querySelectorAll(".new-page-section");

      // Initialize jsPDF
      const pdf = new jsPDF("p", "pt", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const margin = 20;
      const canvasWidth = pdfWidth - margin * 2;

      console.log(pdfHeight);
      // Loop through sections and add them to PDF
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        const canvas = await html2canvas(section, { scale: 2 });
        const imgData = canvas.toDataURL("image/png");
        const canvasHeight =
          (section.offsetHeight * canvasWidth) / section.offsetWidth;

        // Add image to PDF
        if (i > 0) {
          pdf.addPage();
        }
        pdf.addImage(
          imgData,
          "PNG",
          margin,
          margin,
          canvasWidth,
          canvasHeight,
          null,
          "FAST"
        );
      }

      const pdfBlob = pdf.output("blob");

      // Prepare form data
      const formData = new FormData();
      formData.append("caseId", caseID);
      formData.append("formType", "visiting parents (spanish)");
      formData.append("attachmentPath", pdfBlob, `_document.pdf`);
      formData.append("createdBy", userDetails?.id);

      // Send the PDF to the API
      POST("case/case-attachment", formData, true, true)
        .then((resp) => {
          toast.success(resp?.message);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const formik = useFormik({
    initialValues: {
      assignedTo: "",
      methodofCommun: "",
      contactInfo: "",
      email: false,
      phoneCalls: false,
      text: false,
      clientName: "",
      clientAddress: "",
      clientPhone: "",
      clientEmail: "",
      policy1: false,
      policy2: false,
      policy3: false,
      policy4: false,
      policy5: false,
      policy6: false,
      policy7: false,
      policy8: false,
      policy9: false,
      policy10: false,
      policy11: false,
      socialWorker: "",
      case: null,
    },
    validationSchema: Yup.object({
      assignedTo: Yup.string().required("Assigned to is required"),
      methodofCommun: Yup.string().required(
        "Method of communication is required"
      ),
      contactInfo: Yup.string().required("Contact information is required"),
      email: Yup.boolean(),
      phoneCalls: Yup.boolean(),
      text: Yup.boolean(),
      clientName: Yup.string().required("Client name is required"),
      clientAddress: Yup.string().required("Client address is required"),
      clientPhone: Yup.string().required("Client phone number is required"),
      clientEmail: Yup.string().email("Invalid email format"),
      case: Yup.object().required("Case is required"),
    }),
    onSubmit: (values) => {
      saveClientSignature();
      saveSocialSignature();

      setTimeout(() => {
        handleGeneratePDF(values?.case?.CaseID);
      }, [2000]);
    },
  });

  return (
    <Box
      sx={{
        padding: "5px",
        height: "260em",
        overflow: "hidden",
      }}
    >
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <div
            style={{ borderBottom: "1px solid gray", paddingBottom: "100px" }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              flexWrap={"wrap"}
              gap={2}
            >
              <img src={drawerLogo} alt="" style={{ width: "75px" }}></img>
              <Stack alignItems={"flex-end"}>
                <Typography variant="h6">
                  Servicios Integrales Familiares
                </Typography>
                <Typography variant="subtitle1">
                  Servicios Forenses y Terapéuticos
                </Typography>
                <Typography variant="subtitle2">
                  160 E 56 Street, Suite 403, New York, NY, 10007
                </Typography>
                <Typography variant="subtitle2">www.cfs-nyc.com</Typography>
                <Typography variant="subtitle2">(212) 267-2670</Typography>
              </Stack>
            </Stack>

            <br />

            <Stack direction={"row"} justifyContent={"center"}>
              <Typography>
                POLÍTICAS DE VISITACIÓN DE SERVICIOS INTEGRALES FAMILIARES PARA
                PADRES VISITANTES
              </Typography>
            </Stack>
            <br />
            <br />
            <Typography>
              Comprehensive Family Services (CFS) es una agencia privada que
              ofrece servicios de Trabajadores Sociales Licenciados y otros
              profesionales. Los servicios de CFS son designados/dirigidos por
              los Tribunales y también pueden ser contratados de manera privada.
            </Typography>
            <br />
            <Typography>Fecha: {new Date().toString()}</Typography>
            {/* El caso está asignado a */}
            <FormTextInput
              name="assignedTo"
              label="Su caso está asignado a"
              formik={formik}
            />
            <br />
            {/* Método de comunicación */}
            <FormControl>
              <Typography id="demo-controlled-radio-buttons-group">
                El método de comunicación más eficiente de su trabajador social
                es:
              </Typography>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="methodofCommun"
                value={formik.values.methodofCommun}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="Phone/Text"
                  control={<Radio color="black" />}
                  label="Teléfono/Texto"
                />
                <FormControlLabel
                  value="Email"
                  control={<Radio color="black" />}
                  label="Correo Electrónico"
                />
              </RadioGroup>
              {formik.touched.methodofCommun &&
                formik.errors.methodofCommun && (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.methodofCommun}
                  </div>
                )}
            </FormControl>
            {/* Información de contacto del trabajador social */}
            <FormTextInput
              name="contactInfo"
              label="La información de contacto de su trabajador social es"
              formik={formik}
            />
            <br />
            {/* Información de contacto y método de comunicación preferido */}
            <Stack>
              <Typography>
                Por favor, indique su información de contacto y el método de
                comunicación preferido marcando tantos como sea necesario:
              </Typography>
              <FormCheckboxInput
                name="email"
                label="Correo Electrónico"
                formik={formik}
              />
              <FormCheckboxInput
                name="phoneCalls"
                label="Llamadas Telefónicas"
                formik={formik}
              />
              <FormCheckboxInput name="text" label="Texto" formik={formik} />
            </Stack>
            <br />
            <FormTextInput
              name="clientName"
              label="Nombre del Cliente"
              formik={formik}
            />
            <FormTextInput
              name="clientAddress"
              label="Dirección del Cliente"
              formik={formik}
            />
            <FormTextInput
              name="clientPhone"
              label="Teléfono del Cliente"
              formik={formik}
            />
            <FormTextInput
              name="clientEmail"
              label="Correo Electrónico del Cliente"
              formik={formik}
            />

            <br />

            <Typography variant="h5">POLÍTICAS</Typography>

            <br />

            {/* policy 1 */}
            <PolicyCheckbox
              name="policy1"
              label="1. En el caso de que su trabajador social no esté disponible, su trabajador social puede utilizar la asistencia de otro asociado de CFS. Cuando y si esto ocurre, se le proporcionará la información de contacto del trabajador social delegado."
              formik={formik}
            />

            {/* policy 2 */}
            <PolicyCheckbox
              name="policy2"
              label="2. El papel de CFS es el de una parte neutral. Esto potencialmente implica la liberación de toda la información relevante recopilada y observada durante nuestra participación con su familia ante el Tribunal."
              formik={formik}
            />

            {/* policy 3 */}
            <PolicyCheckbox
              name="policy3"
              label="<b>3. Puntualidad:</b> <br /> Se debe hacer todo lo posible para llegar a su hora programada/instruida. Si las partes siguen las directrices de su trabajador social y son puntuales, esto debería evitar que cualquiera de las partes entre en contacto con la otra. En caso de cualquier posible retraso, debe comunicarlo a su trabajador social lo antes posible y mantenerse en comunicación con su trabajador social hasta su llegada. Los padres visitantes, cuyas visitas se realizan en la oficina de CFS, deben llegar quince (15) minutos antes de la hora de visita programada y se marcharán quince (15) minutos después de la salida del padre/madre con custodia/el niño.
  <br />
  Periódicamente, su trabajador social puede cambiar este protocolo a su discreción. El protocolo para visitas realizadas en la comunidad es dirigido por el Tribunal o arreglado por las partes y/o el trabajador social según las circunstancias del caso. CFS siempre retiene la discreción y autoridad para dirigir a las partes con respecto a este protocolo."
              formik={formik}
            />

            {/* policy 4 */}
            <PolicyCheckbox
              name="policy4"
              label="<b>4. Asistencia:</b> <br/> La asistencia regular y consistente es necesaria para cumplir con los requisitos de visita. Una vez que se programa una sesión, ese horario está reservado específicamente para usted. Se espera que los clientes de CFS asistan a todas las sesiones programadas por su Trabajador Social, excepto en caso de enfermedad o emergencia. Tres o más cancelaciones durante un período de dos meses (60 días) o dos cancelaciones consecutivas pueden resultar en la terminación de los servicios y el retiro de CFS del caso."
              formik={formik}
            />

            {/* policy 5 */}
            <PolicyCheckbox
              name="policy5"
              label="<b>5. Cancelaciones:</b> <br/> Todas las cancelaciones requieren un aviso mínimo de veinticuatro (24) horas y deben ser confirmadas por su trabajador social. No cumplir con esta política resultará en una tarifa de cancelación que se le cobrará a la misma tarifa por hora por el tiempo que estaba programado, según el contrato firmado con CFS.
  Si está recibiendo servicios de CFS sin costo y pagados por el Tribunal y fondos públicos, el Tribunal aún puede ordenar que usted sea responsable de las citas canceladas con CFS. En caso de que esto se ordene, se le cobrará a la tarifa por hora de $75/hora."
              formik={formik}
            />

            {/* policy 6 */}
            <PolicyCheckbox
              name="policy6"
              label="<b>6. Visitas Supervisadas:</b> <br/> Su trabajador social facilitará la transición de su(s) hijo(s) hacia y desde usted. El supervisor estará presente durante toda la visita y se espera que pueda ver y/o escuchar todas las interacciones y comunicaciones. Por favor, tenga esto en cuenta al planificar sus actividades, como ir al parque infantil y llevar a su hijo al baño. Si solicita utilizar la propiedad de CFS, como juguetes/juegos/libros, se espera que limpie después de su hijo y de usted mismo y deje la propiedad de CFS en su estado original.<br/>
  El objetivo de la visita supervisada es permitirle pasar tiempo positivo y adecuado con su(s) hijo(s) y evitar su exposición a conflictos familiares. No se permite la discusión sobre el litigio, la descalificación del otro padre ni la obtención de información personal sobre el otro padre durante su visita. Su trabajador social mantiene la autoridad para intervenir y redirigir cualquier comunicación o comportamiento que se considere inapropiado. CFS también retiene la autoridad para terminar su visita en caso de que no se cumpla esta política.<br/>
  El personal de CFS y la oficina de CFS no deben ser utilizados con el propósito de proseguir litigios. La política de CFS prohíbe la entrega de documentos legales antes, durante o después de la visita de CFS y en el espacio de la oficina de CFS."
              formik={formik}
            />

            {/* policy 7 */}
            <PolicyCheckbox
              name="policy7"
              label="<b>7. Uso de Dispositivos de Grabación:</b> <br/> No se permiten dispositivos de grabación (audio o video) durante la visita. Por favor, discuta sus intenciones de llevar cualquier video/foto a las visitas con su trabajador social antes de su visita. Si desea tomar fotografías o videos cortos de su hijo durante su visita, consulte a su trabajador social con anticipación."
              formik={formik}
            />

            {/* policy 8 */}
            <PolicyCheckbox
              name="policy8"
              label="<b>8. Programación:</b> <br/> La programación de las visitas se organiza teniendo en cuenta la disponibilidad de ambas partes, incluido el niño y el Trabajador Social de CFS. Mientras que CFS se esfuerza por ofrecer disponibilidad de personal flexible, su cooperación es esencial para completar los requisitos mandados por el Tribunal."
              formik={formik}
            />

            {/* policy 9 */}
            <PolicyCheckbox
              name="policy9"
              label="<b>9. Regalos:</b> <br/> Si desea dar un regalo a su hijo, debe consultarlo con su trabajador social antes de su visita. CFS retiene la discreción para permitirle dar regalos a sus hijos durante la visita. En caso de que se le permita dar regalos a su(s) hijo(s), tenga en cuenta que el tamaño del regalo y la cantidad pueden influir en si se le permite dárselos durante su visita."
              formik={formik}
            />

            {/* policy 10 */}
            <PolicyCheckbox
              name="policy10"
              label="<b>10. Comida y Artículos de Aseo:</b> <br/> CFS no proporciona pañales ni comida. Su trabajador social discutirá con usted cualquier requisito específico/suministros necesarios para su hijo antes de su visita."
              formik={formik}
            />

            {/* policy 11 */}
            <PolicyCheckbox
              name="policy11"
              label="<b>11. Terceras Partes:</b> <br/> Generalmente, a menos que sea autorizado por el Tribunal o que la otra parte consienta, las visitas son únicamente para la parte visitante. Por favor, discuta con su trabajador social asignado cualquier plan para tener otros visitantes durante su visita."
              formik={formik}
            />

            {/* nombre y firma del cliente */}
            <FormTextInput
              name="clientName"
              label="Nombre del Cliente"
              formik={formik}
            />
            <br />

            <Stack direction={"row"} gap={2}>
              <Typography>Firma:</Typography>

              <Box
                sx={{ borderBottom: "1px solid black", width: "fit-content" }}
              >
                <SignatureCanvas
                  ref={clientSigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 400,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
                <Stack
                  direction="row"
                  gap={2}
                  p={2}
                  justifyContent={"flex-end"}
                >
                  <ButtonCommon
                    btnClicked={clearClientSignature}
                    startIcon={false}
                  >
                    Limpiar
                  </ButtonCommon>
                </Stack>
              </Box>
            </Stack>

            <br />

            {/* nombre y firma del trabajador social */}
            <FormTextInput
              name="socialWorker"
              label="Trabajador Social"
              formik={formik}
            />
            <br />

            <Stack direction={"row"} gap={2}>
              <Typography>Firma:</Typography>

              <Box
                sx={{ borderBottom: "1px solid black", width: "fit-content" }}
              >
                <SignatureCanvas
                  ref={socialSigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 400,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
                <Stack
                  direction="row"
                  gap={2}
                  p={2}
                  justifyContent={"flex-end"}
                >
                  <ButtonCommon
                    btnClicked={clearSocialSignature}
                    startIcon={false}
                  >
                    Limpiar
                  </ButtonCommon>
                </Stack>
              </Box>
            </Stack>

            <br />

            <Typography>Fecha: {new Date().toString()}</Typography>
          </div>

          <Stack direction={"row"} p={2} pb={6} gap={4}>
            <ButtonCommon
              startIcon={false}
              btnClicked={() => {
                setAttachTocase(true);
              }}
            >
              Adjuntar al Caso
            </ButtonCommon>

            <ButtonCommon
              btnClicked={() => {
                saveClientSignature();
                saveSocialSignature();
                setTimeout(() => {
                  generatePDF(contentRef, options);
                }, [1000]);
              }}
              startIcon={false}
            >
              Descargar PDF
            </ButtonCommon>
          </Stack>

          {attachTocase && (
            <Stack
              gap={2}
              alignItems={"flex-start"}
              justifyContent={"center"}
              sx={{ width: "fit-content" }}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Typography>¿A qué caso le gustaría adjuntar?</Typography>
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setAttachTocase(false);
                  }}
                />
              </Stack>

              <Stack>
                <Typography sx={{ textAlign: "left" }}>
                  Seleccione el caso<span style={{ color: "red" }}>*</span>
                </Typography>
                <Stack sx={{ width: "400px" }}>
                  {/* <AutocompleteInput
                    id="case"
                    name="case"
                    options={caseDropdown}
                    value={formik.values.case}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("case", newValue);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.case && Boolean(formik.errors.case)}
                    helperText={formik.touched.case && formik.errors.case}
                  /> */}
                  <CaseSearchDropdown
                    name="case"
                    value={formik.values.case}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("case", newValue);
                    }}
                    onBlur={formik.handleBlur}
                    reset={resetDropdown}
                  />
                  {formik.touched.case && formik.errors.case ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.case}
                    </div>
                  ) : null}{" "}
                </Stack>
              </Stack>

              <ButtonCommon startIcon={false} type={"submit"}>
                Adjuntar
              </ButtonCommon>
            </Stack>
          )}
        </form>
      </Box>

      <Box style={{ height: "250px", width: "100%" }}></Box>
      <br />
      <br />

      <div
        ref={contentRef}
        style={{
          borderBottom: "1px solid gray",
          paddingBottom: "100px",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="new-page-section">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <img src={drawerLogo} alt="" style={{ width: "75px" }}></img>
              <Stack alignItems={"flex-end"}>
                <Typography variant="h6">
                  Servicios Integrales Familiares
                </Typography>
                <Typography variant="subtitle1">
                  Servicios Forenses y Terapéuticos
                </Typography>
                <Typography variant="subtitle2">
                  160 E 56 Street, Suite 403, New York, NY, 10007
                </Typography>
                <Typography variant="subtitle2">www.cfs-nyc.com</Typography>
                <Typography variant="subtitle2">(212) 267-2670</Typography>
              </Stack>
            </Stack>
            <br />
            <Stack direction={"row"} justifyContent={"center"}>
              <Typography>
                POLÍTICAS DE VISITACIÓN DE SERVICIOS INTEGRALES FAMILIARES PARA
                PADRES VISITANTES
              </Typography>
            </Stack>
            <br />
            <Typography>
              Comprehensive Family Services (CFS) es una agencia privada que
              brinda servicios de Trabajadores Sociales Licenciados y otros
              profesionales. Los servicios de CFS son designados/dirigidos por
              los Tribunales y también pueden ser contratados de manera privada.
            </Typography>
            <br />
            <Typography>Fecha: {new Date().toString()}</Typography>
            {/* case is assigned to  */}
            <FormTextInput
              name="assignedTo"
              label="Su caso está asignado a"
              formik={formik}
            />
            <br />
            {/* method of communication  */}
            <FormControl>
              <Typography id="demo-controlled-radio-buttons-group">
                El método de comunicación más eficiente de su trabajador social
                es:
              </Typography>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="methodofCommun"
                value={formik.values.methodofCommun}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="Phone/Text"
                  control={<Radio color="black" />}
                  label="Teléfono/Texto"
                />
                <FormControlLabel
                  value="Email"
                  control={<Radio color="black" />}
                  label="Correo Electrónico"
                />
              </RadioGroup>
              {formik.touched.methodofCommun &&
                formik.errors.methodofCommun && (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.methodofCommun}
                  </div>
                )}
            </FormControl>
            {/* social worker contact information  */}
            <FormTextInput
              name="contactInfo"
              label="La información de contacto de su trabajador social es"
              formik={formik}
            />
            <br />
            {/* contact information and preferred method of communication  */}
            <Stack>
              <Typography>
                Indique su información de contacto y método de comunicación
                preferido marcando tantos como sea necesario:
              </Typography>
              <FormCheckboxInput
                name="email"
                label="Correo Electrónico"
                formik={formik}
              />
              <FormCheckboxInput
                name="phoneCalls"
                label="Llamadas Telefónicas"
                formik={formik}
              />
              <FormCheckboxInput name="text" label="Texto" formik={formik} />
            </Stack>
            <br />
            <FormTextInput
              name="clientName"
              label="Nombre del Cliente"
              formik={formik}
            />
            <FormTextInput
              name="clientAddress"
              label="Dirección del Cliente"
              formik={formik}
            />
            <FormTextInput
              name="clientPhone"
              label="Teléfono del Cliente"
              formik={formik}
            />
            <FormTextInput
              name="clientEmail"
              label="Correo Electrónico del Cliente"
              formik={formik}
            />
            <br />
            <Typography variant="h5">POLÍTICAS</Typography>
            <br />
            {/* policy 1  */}
            <PolicyCheckbox
              name="policy1"
              label="1. En caso de que su trabajador social no esté disponible, su trabajador social puede utilizar la asistencia de otro asociado de CFS. Cuando y si esto ocurre, se le proporcionará la información de contacto del trabajador social delegado."
              formik={formik}
            />
            {/* policy 2  */}
            <PolicyCheckbox
              name="policy2"
              label="2. El papel de CFS es el de una parte neutral. Esto potencialmente implica la divulgación de toda la información relevante recopilada y observada a lo largo de nuestra participación con su familia al Tribunal."
              formik={formik}
            />
            {/* policy 3  */}
            <PolicyCheckbox
              name="policy3"
              label="<b>3. Puntualidad:</b> <br /> Se debe hacer todo lo posible para llegar a la hora programada/instruida. Si las partes siguen las directrices de su trabajador social y son puntuales, esto debería evitar que alguna de las partes entre en contacto con la otra. En caso de cualquier posible retraso, debe comunicarlo a su trabajador social tan pronto como sea posible y mantenerse en comunicación con su trabajador social hasta su llegada. Los padres visitantes, cuyas visitas se realizan en la oficina de CFS, deben llegar quince (15) minutos antes de la hora programada de la visita y partir quince (15) minutos después de la salida del padre/madre/tutor custodial con el niño.
          <br />
          Periódicamente, su trabajador social puede cambiar este protocolo a su discreción. El protocolo para las visitas realizadas en la comunidad es dirigido por el Tribunal o arreglado por las partes y/o el trabajador social según las circunstancias del caso. CFS siempre mantiene la discreción y autoridad para dirigir a las partes en relación con este protocolo."
              formik={formik}
            />
            {/* policy 4  */}
            <PolicyCheckbox
              name="policy4"
              label="<b>4. Asistencia</b> <br/>
          La asistencia regular y constante es necesaria para cumplir con los requisitos de visitación. Una vez que se programa una sesión, ese espacio de tiempo se reserva específicamente para usted. Se espera que los clientes de CFS asistan a todas las sesiones programadas por su trabajador social, excepto en caso de enfermedad o emergencia. Tres o más cancelaciones durante un período de dos meses (60 días) o dos cancelaciones consecutivas pueden resultar en la terminación de los servicios y la retirada de CFS del caso."
              formik={formik}
            />
            {/* policy 5  */}
            <PolicyCheckbox
              name="policy5"
              label="<b>5. Cancelaciones:</b> <br/>
          Todas las cancelaciones requieren un mínimo de veinticuatro (24) horas de aviso y deben ser confirmadas por su trabajador social. El incumplimiento de esta política resultará en una tarifa de cancelación que se le cobrará a la misma tarifa por hora por el tiempo que se le programó, según el contrato firmado con CFS.
          Si recibe servicios de CFS sin cargo y pagados por el Tribunal y fondos públicos, el Tribunal aún puede ordenar que usted sea responsable de las citas canceladas con CFS. En caso de que esto se ordene, se le cobrará a una tarifa por hora de $75/hora."
              formik={formik}
            />
          </div>
          <div className="new-page-section">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <img src={drawerLogo} alt="" style={{ width: "75px" }}></img>
              <Stack alignItems={"flex-end"}>
                <Typography variant="h6">
                  Servicios Integrales Familiares
                </Typography>
                <Typography variant="subtitle1">
                  Servicios Forenses y Terapéuticos
                </Typography>
                <Typography variant="subtitle2">
                  160 E 56 Street, Suite 403, New York, NY, 10007
                </Typography>
                <Typography variant="subtitle2">www.cfs-nyc.com</Typography>
                <Typography variant="subtitle2">(212) 267-2670</Typography>
              </Stack>
            </Stack>
            <br />
            {/* policy 6  */}
            <PolicyCheckbox
              name="policy6"
              label="<b>6. Visitas Supervisadas:</b> <br/>
          Su trabajador social facilitará la transición de su/s hijo/s hacia y desde usted. El supervisor estará presente durante la duración de su visita y se espera que pueda ver y/o escuchar todas las interacciones y comunicaciones. Por favor, tenga esto en cuenta al planear sus actividades, como ir al parque infantil y llevar a su hijo al baño. Si solicita usar la propiedad de CFS, como juguetes/juegos/libros, se espera que limpie después de su hijo y de usted mismo y deje la propiedad de CFS en su estado original.<br/>
          El objetivo de la visita supervisada es permitirle pasar tiempo positivo y apropiado con su/s hijo/s y evitar su exposición a conflictos familiares. No se permite discutir sobre el litigio, desprestigiar al otro padre o solicitar información personal sobre el otro padre durante su visita. Su trabajador social mantiene la autoridad para intervenir y redirigir cualquier comunicación o comportamiento que se considere inapropiado. CFS también mantiene la autoridad para terminar su visita en caso de que no se cumpla con esta política.<br/>
          El personal de CFS y la oficina de CFS no deben ser utilizados con el propósito de perseguir litigios. La política de CFS prohíbe el servicio de documentos legales antes, durante o después de la visitación de CFS y en el espacio de la oficina de CFS."
              formik={formik}
            />
            {/* policy 7  */}
            <PolicyCheckbox
              name="policy7"
              label="<b>7. Uso de Dispositivos de Grabación:</b> <br/>
          No se permiten dispositivos de grabación (audio o video) durante la visitación. Por favor, discuta sus intenciones de llevar videos/fotos a las visitas con su trabajador social antes de su visitación. Si desea tomar fotografías o videos cortos de su hijo durante su visitación, consulte a su trabajador social con anticipación."
              formik={formik}
            />
            {/* policy 8  */}
            <PolicyCheckbox
              name="policy8"
              label="<b>8. Programación:</b> <br/>
          La programación de visitas se organiza considerando la disponibilidad de ambas partes, incluido el niño y el trabajador social de CFS. Aunque CFS se esfuerza por ofrecer disponibilidad de personal flexible, su cooperación es esencial para cumplir con los requisitos mandados por el Tribunal."
              formik={formik}
            />
            {/* policy 9  */}
            <PolicyCheckbox
              name="policy9"
              label="<b>9. Regalos:</b> <br/>
          Si desea dar un regalo a su hijo, debe consultar sobre esto con su trabajador social antes de su visitación. CFS mantiene la discreción en permitirle dar regalos a sus hijos durante la visitación. En caso de que se le permita dar regalos a sus hijos, tenga en cuenta que el tamaño del regalo y la cantidad pueden afectar si se le permite darlos durante su visitación."
              formik={formik}
            />
            {/* policy 10  */}
            <PolicyCheckbox
              name="policy10"
              label="<b>10. Comida y Artículos de Tocador:</b> <br/>
          CFS no proporciona pañales ni comida. Su trabajador social discutirá con usted cualquier requisito específico/suministros necesarios para su hijo antes de su visitación."
              formik={formik}
            />
            {/* policy 11  */}
            <PolicyCheckbox
              name="policy11"
              label="<b>11. Terceras Partes:</b> <br/>
          Generalmente, a menos que el Tribunal lo autorice o la otra parte lo consienta, las visitas son únicamente para la parte visitante. Por favor, discuta con su trabajador social asignado cualquier plan para tener otros visitantes durante su visitación."
              formik={formik}
            />
            <Stack direction={"row"}>
              {/* client name and signature  */}
              <Box sx={{ width: "50%" }}>
                <FormTextInput
                  name="clientName"
                  label="Nombre del Cliente"
                  formik={formik}
                />
                <br />
                <Stack direction={"row"} gap={2}>
                  <Typography>Firma:</Typography>

                  <img src={clientSign} alt=""></img>
                </Stack>
              </Box>
              {/* social worker name and signature  */}
              <Box sx={{ width: "50%" }}>
                <FormTextInput
                  name="socialWorker"
                  label="Trabajador Social"
                  formik={formik}
                />
                <br />
                <Stack direction={"row"} gap={2}>
                  <Typography>Firma:</Typography>

                  <img src={workerSign} alt=""></img>
                </Stack>
              </Box>
            </Stack>
            <br />
            <Typography>Fecha: {new Date().toString()}</Typography>
          </div>
        </form>
      </div>
    </Box>
  );
};

export default VisitingForm;
