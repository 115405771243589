import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, Stack, Typography } from "@mui/material";
import TextInput from "../../shared/TextInput";
import ButtonCommon from "../../shared/ButtonCommon";
import { PUT } from "../../api/ApiService";
import { toast } from "react-toastify";
import EncryptionHelper from "../../shared/EncryptionHelper";
import NumberInput from "../../shared/NumberInput";

const EditUsers = ({ editUserData, closeEditHandler, fetchUsers }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userId: editUserData?.UserID,
      name: editUserData?.Username,
      email: editUserData?.Email,
      nickname: editUserData?.nickname,
      billingId: editUserData?.BillingID,
    },
    validationSchema: Yup.object({
      //   nickname: Yup.string().required("Case number is required"),
      //   billingId: Yup.string().required("Case name is required"),
    }),
    onSubmit: (values) => {
      const putData = {
        userid: values?.userId,
        nickname: values?.nickname,
        billingId: values?.billingId,
      };
      console.log("data to be encrypted =>", putData);
      const encryptData = EncryptionHelper.encryptData(putData);
      PUT("update-user", { encryptData })
        .then((resp) => {
          toast.success(resp?.message);
          closeEditHandler();
          fetchUsers();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
        });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} p={4}>
          {/* 1  */}
          <Grid item xs={12}>
            <Typography variant="h5">Update</Typography>
          </Grid>

          {/* 2 name */}
          <Grid item xs={12}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Name</Typography>
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="name"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.name}
                  startIcon={false}
                  disabled={true}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.name}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 3 email */}
          <Grid item xs={12}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Email</Typography>
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="email"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.email}
                  startIcon={false}
                  disabled={true}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.email}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 3 nickname */}
          <Grid item xs={12}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Nickname</Typography>
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="nickname"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.nickname}
                  startIcon={false}
                  disabled={false}
                />
                {formik.touched.nickname && formik.errors.nickname ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.email}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 3 billing id */}
          <Grid item xs={12}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>billingId</Typography>
              <Stack sx={{ width: "100%" }}>
                <NumberInput
                  name="billingId"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.billingId}
                  startIcon={false}
                  helperText={"only numerical values are allowed"}
                />
                {formik.touched.billingId && formik.errors.billingId ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.billingId}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>

          {/* 3 */}
          <Grid item xs={12}>
            <Stack direction={"row"} gap={2} justifyContent={"flex-end"}>
              <ButtonCommon type="submit">Save</ButtonCommon>
              <ButtonCommon btnClicked={closeEditHandler}>Cancel</ButtonCommon>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EditUsers;
