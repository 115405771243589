import React from "react";
import { Box, 
  Stack, 
  Typography, 
  //Button, 
  CssBaseline } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MicrosoftLogin from "react-microsoft-login";

// assets
import logo from "../../assets/logo.png";

// mui icons
import { useNavigate } from "react-router-dom";
import axios from "axios";
//import PasswordInput from "../../shared/PasswordInput";
import { useDispatch } from "react-redux";
import { storeUserDetails } from "../../slices/userSlice";
import { POST } from "../../api/ApiService";
//import EmailInput from "../../shared/EmailInput";
import EncryptionHelper from "../../shared/EncryptionHelper";

const baseURl = process.env.REACT_APP_API_BASE_URL;
const apiKEY = process.env.REACT_APP_API_KEY;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const forgotClickHandler = () => {
  //   navigate("/forgotpassword");
  // };

  const authHandler = (err, data) => {
    console.warn("err or data from login with microsoft =>", err, data);
    if (data) {
      axios
        .post(
          `${baseURl}/microsoft-auth`,
          {
            accessToken: data.accessToken,
          },
          {
            headers: {
              Authorization: `Bearer ${data.accessToken}`,
              "X-API-Key": apiKEY,
            },
          }
        )
        .then((resp) => {
          toast.success(resp?.message);
          localStorage.setItem("token", resp?.data?.token);
          dispatch(storeUserDetails(resp?.data?.user));
          navigate("/dashboard");
        })
        .catch((err) => {
          console.warn("err of our api =>", err);
        });

      // navigate("/dashboard");

      // axios
      //   .get("https://graph.microsoft.com/v1.0/me", {
      //     headers: {
      //       Authorization: `Bearer ${data.accessToken}`,
      //     },
      //   })
      //   .then((resp) => {
      //     console.log("resp of get user data =>", resp);
      //   })
      //   .catch((err) => {
      //     console.log("err of get user data =>", err);
      //   });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Password is required"),
    }),
    onSubmit: (values) => {
      console.log("data to be encrypted ==>", {
        email: values.email,
        password: values.password,
      });

      const encryptData = EncryptionHelper.encryptData({
        email: values.email,
        password: values.password,
      });

      POST("login", { encryptData }, false)
        .then((resp) => {
          toast.success(resp?.message);
          localStorage.setItem("token", resp?.token);
          dispatch(storeUserDetails(resp?.user));
          navigate("/dashboard");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
        });
    },
  });

  return (
    <Box sx={{ height: "100vh" }}>
      <CssBaseline />
      <Stack sx={{ height: "100vh", pb: 4 }}>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: "225px",
              marginTop: "40px",
              marginBottom: "20px",
            }}
          ></img>
        </Stack>

        <form onSubmit={formik.handleSubmit}>
          <Stack
            justifyContent="center"
            alignItems="center"
            gap={2}
            width="30%"
            minWidth={"280px"}
            m="auto"
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "600",
                lineHeight: "45px",
                letterSpacing: "0.10000000149011612px",
                textAlign: "center",
                cursor: "default",
              }}
            >
              Welcome to Simone
            </Typography>

            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                lineHeight: "45px",
                letterSpacing: "0.10000000149011612px",
                textAlign: "center",
                cursor: "default",
              }}
            >
              Your Partner in Case Management
            </Typography>

            {/* <Box sx={{ width: "100%" }}>
              <EmailInput
                name="email"
                placeholder="Email"
                onChangeValue={formik.handleChange}
                onBlurValue={formik.handleBlur}
                value={formik.values.email}
              />

              {formik.touched.email && formik.errors.email ? (
                <div
                  style={{
                    fontSize: "smaller",
                    color: "red",
                    textAlign: "left",
                  }}
                >
                  {formik.errors.email}
                </div>
              ) : null}
            </Box>
            <Box sx={{ width: "100%" }}>
              <PasswordInput
                name="password"
                placeholder="Password"
                onChangeValue={formik.handleChange}
                onBlurValue={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <div
                  style={{
                    fontSize: "smaller",
                    color: "red",
                    textAlign: "left",
                  }}
                >
                  {formik.errors.password}
                </div>
              ) : null}
            </Box>
            <Button type="submit" variant="contained" fullWidth>
              Log In
            </Button>
            <Stack direction="row" justifyContent="flex-end" width={"100%"}>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  letterSpacing: "0.10000000149011612px",
                  textAlign: "center",
                  color: "#0062FF",
                  cursor: "pointer",
                }}
                onClick={forgotClickHandler}
              >
                Forgot password?
              </Typography>
            </Stack>

            <Stack direction="row">
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "21px",
                  letterSpacing: "0.10000000149011612px",
                  textAlign: "center",
                  color: "#969AB8",
                  cursor: "default",
                }}
              >
                or
              </Typography>
            </Stack> */}

            <MicrosoftLogin
              clientId={"0533a5b3-b2d6-4d5d-8304-19d5a9af111d"}
              authCallback={authHandler}
            />
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};

export default Login;
