import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import { DELETE, GET } from "../../api/ApiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EncryptionHelper from "../../shared/EncryptionHelper";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { storeEditDar } from "../../slices/editDarSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";

const headerConfig = [
  { id: "actions", label: "Edit", sortable: false },
  { id: "SocialWorker", label: "User", sortable: true },
  { id: "CaseName", label: "Case Name", sortable: true },
  { id: "CaseNumber", label: "Case Number", sortable: true },
  { id: "StartTime", label: "Start Time", sortable: true },
  { id: "FinishTime", label: "End Time", sortable: true },
  { id: "ServiceOrActivity", label: "Activity", sortable: true },
  { id: "CreatedAt", label: "Created At", sortable: true},
];

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    // if (page > 1) {
    onPageChange(event, page - 1);
    // }
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: "2.5rem" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </div>
  );
}

const MyDar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const [data, setData] = useState([]);
  const [totalTime, setTotalTime] = useState();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  useEffect(() => {
    fetchDar(page);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
    fetchDar(0);
  }, [activeFilter]);

  const fetchDar = (pageNo) => {
    setLoading(true);

    GET(
      `daily-activity/get-reports/${userDetails?.id}?pageNumber=${
        pageNo + 1
      }&pageSize=${rowsPerPage}&period=${activeFilter}`
    )
      .then((response) => {
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        console.log("decryptedData of dar =>", decryptedData);

        const reports = decryptedData?.reports;

        // Function to parse and convert time
        const parseTime = (timeStr) => {
          const [hours, minutes, seconds] = timeStr.split(":").map(Number);
          return (
            (hours || 0) * 3600 + // convert hours to seconds
            (minutes || 0) * 60 + // convert minutes to seconds
            (seconds || 0) // seconds
          );
        };

        // Function to format total seconds into hours
        const formatTime = (totalSeconds) => {
          return `${(totalSeconds / 3600.0).toFixed(2)}`;
        };

        // Function to sum time spent from reports
        const sumTimeSpent = (data) => {
          let totalSeconds = 0;

          data.forEach((item) => {
            totalSeconds += parseTime(item.TimeSpent);
          });

          return formatTime(totalSeconds);
        };

        // Sum the time spent in all reports
        const totalTimeSpent = sumTimeSpent(reports);

        // Set data and total time
        setData(reports);
        setTotalTime(totalTimeSpent); // Update this line to set the formatted total time
        setTotalRows(decryptedData?.totalData);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteBtnHandler = () => {
    const deleteData = {
      ids: selected,
    };

    console.log("data to be encrypted =>", deleteData);

    const encryptData = EncryptionHelper.encryptData(deleteData);

    DELETE(`daily-activity/delete`, { encryptData })
      .then((resp) => {
        toast.success(resp?.message);
        setToggleClearRows(!toggledClearRows);
        fetchDar(0);
        setSelected([]);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const lastpayClickHandler = () => {
    setActiveFilter("last");
  };

  const currentpayClickHandler = () => {
    setActiveFilter("current");
  };

  const allActivityClickHandler = () => {
    setActiveFilter("all");
  };

  const handleSelectAllClick = (event) => {
    /*
    if (event.target.checked) {
      const newSelected = data.map((n) => n.ReportID);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }*/
  };

  const handleClick = (event, id) => {
    event.stopPropagation(); // Prevent row click from affecting checkbox selection
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isDateField = (field) => {
    return ["ReceivedDate", "AdjournedDate", "CreatedAt", "UpdatedAt"].includes(
      field
    );
  };

  const sortedData = data.slice().sort((a, b) => {
    if (orderBy) {
      const valueA = a[orderBy] || "";
      const valueB = b[orderBy] || "";

      if (isDateField(orderBy)) {
        const dateA =
          valueA === "null" || valueA === "Invalid date"
            ? null
            : new Date(valueA);
        const dateB =
          valueB === "null" || valueB === "Invalid date"
            ? null
            : new Date(valueB);

        // Handle null values: place them at the end for ascending order and at the start for descending order
        if (dateA === null && dateB === null) return 0;
        if (dateA === null) return order === "asc" ? 1 : -1;
        if (dateB === null) return order === "asc" ? -1 : 1;

        // Handle Invalid dates
        if (isNaN(dateA) && isNaN(dateB)) return 0; // Both are invalid dates
        if (isNaN(dateA)) return order === "asc" ? 1 : -1; // dateA is invalid
        if (isNaN(dateB)) return order === "asc" ? -1 : 1; // dateB is invalid

        return order === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        return order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
    }
    return 0;
  });

  return (
    <Box>
      <MainHeader headingText="My DARs" />

      <Stack
        gap={3}
        p={3}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        <Stack
          direction={"row"}
          gap={4}
          alignItems={"center"}
          flexWrap={"wrap"}
        >
          <Button
            startIcon={false}
            variant={activeFilter === "all" ? "contained" : "outlined"}
            color={"shamrock"}
            sx={{ color: activeFilter === "all" ? "white" : "black" }}
            onClick={allActivityClickHandler}
          >
            All Activity
          </Button>
          <Button
            startIcon={false}
            variant={activeFilter === "last" ? "contained" : "outlined"}
            color={"shamrock"}
            sx={{ color: activeFilter === "last" ? "white" : "black" }}
            onClick={lastpayClickHandler}
          >
            Last Pay Period
          </Button>
          <Button
            startIcon={false}
            variant={activeFilter === "current" ? "contained" : "outlined"}
            color={"shamrock"}
            sx={{ color: activeFilter === "current" ? "white" : "black" }}
            onClick={currentpayClickHandler}
          >
            Current Pay Period
          </Button>

          {selected?.length > 0 && (
            <Button
              variant="contained"
              color="red"
              sx={{
                color: "white",
              }}
              onClick={deleteBtnHandler}
            >
              Delete
            </Button>
          )}
        </Stack>

        {loading ? (
          <Stack justifyContent={"center"} alignItems={"center"} height={400}>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {sortedData?.length > 0 ? (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            indeterminate={
                              selected.length > 0 &&
                              selected.length < data.length
                            }
                            checked={
                              data.length > 0 && selected.length === data.length
                            }
                            onChange={handleSelectAllClick}
                          />
                        </TableCell>

                        {headerConfig.map((header) => (
                          <TableCell key={header.id}>
                            {header.sortable ? (
                              <TableSortLabel
                                active={orderBy === header.id}
                                direction={
                                  orderBy === header.id ? order : "asc"
                                }
                                onClick={() => handleRequestSort(header.id)}
                              >
                                {header.label}
                              </TableSortLabel>
                            ) : (
                              header.label
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData.map((row) => {
                        const isItemSelected =
                          selected.indexOf(row.ReportID) !== -1;
                        return (
                          <TableRow
                            key={row.ReportID}
                            role="checkbox"
                            selected={isItemSelected}
                            onClick={(event) => {
                              // Prevent row click from affecting checkbox selection
                              event.stopPropagation();
                            }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) =>
                                  handleClick(event, row.ReportID)
                                }
                                // Prevent checkbox click from triggering the row click event
                                onClick={(event) => event.stopPropagation()}
                              />
                            </TableCell>
                            <TableCell>
                              <EditIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  navigate("/editdar");
                                  dispatch(storeEditDar(row));
                                }}
                              />
                            </TableCell>

                            <TableCell>{row.WorkerName}</TableCell>

                            <TableCell
                              sx={{ cursor: "pointer", color: "#01997D" }}
                            >
                              {row.CaseName}
                            </TableCell>
                            <TableCell>{row.CaseNumber}</TableCell>
                            <TableCell>
                              {row.StartTime
                                ? moment(row.StartTime).format("LLL")
                                : "null"}
                            </TableCell>
                            <TableCell>
                              {row.FinishTime
                                ? moment(row.FinishTime).format("LLL")
                                : "null"}
                            </TableCell>
                            <TableCell>{row.ServiceOrActivity}</TableCell>
                            <TableCell>
                              {row.CreatedAt
                                ? moment.utc(row.CreatedAt).local().format("LLL")
                                : "null"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[30, 100, 200]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </>
            ) : (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                height={200}
              >
                No Reports Found
              </Stack>
            )}
          </>
        )}

        <Stack direction="row" justifyContent={"center"}>
          <Typography>
            <b>Total Time:</b> {totalTime} hours
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default MyDar;
