import { Grid, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextInput from "../../shared/TextInput";
import ButtonCommon from "../../shared/ButtonCommon";
import NumberInput from "../../shared/NumberInput";
// import TextAreaInput from "../../shared/TextAreaInput";
import DateInput from "../../shared/DateInput";
import SelectInput from "../../shared/SelectInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GET, POST } from "../../api/ApiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckboxInput from "../../shared/CheckboxInput";
import EncryptionHelper from "../../shared/EncryptionHelper";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import RichTextEditor from "../../shared/RichTextEditor";
import {
  courtDropdown,
  judgeDropdown,
  statusData,
  appearanceReport,
  caseTypeData,
  serviceLocationData,
} from "../AddEditConstants/index";
import UserSearchDropdown from "../../shared/UserSearchDropdown";
import DarkLightMode from "../../shared/DarkLightMode";

const WufooToCase = ({ closeDialogHandler }) => {
  const theme = useTheme();
  const userDetails = useSelector((state) => state.userReducer.userDetails);
  const WufooData = useSelector((state) => state.wufooCaseReducer.wufooCase);

  const [editorValue, setEditorValue] = useState("");
  const [swData, setSwData] = useState([]);

  const [resetDropdown, setResetDropdown] = useState(false);

  useEffect(() => {
    GET("users")
      .then((resp) => {
        const encryptedData = resp?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        setSwData(decryptedData?.users);
      })
      .catch((err) => {
        console.warn("error =>", err);
      });
  }, []);

  useEffect(() => {
    if (resetDropdown) {
      setResetDropdown(false);
    }
  }, [resetDropdown]);

  const handleEditorChange = (value) => {
    setEditorValue(value);
    formik.setFieldValue("editorContent", value);

    if (value == "<p><br></p>") {
      setEditorValue("");
      formik.setFieldValue("editorContent", "");
    }
  };

  const formik = useFormik({
    initialValues: {
      caseNumber: "",
      caseName: WufooData?.CaseName,
      docketNumber: WufooData?.FileNumber.toString(),
      voucherNumber: WufooData?.FileNumber.toString(),
      assignedTo: null,
      secondarySW: [],
      status: "Open",
      hoursLimit: WufooData?.HoursPerSession * WufooData?.NoOfSessions,
      comments: "",
      editorContent: "",
      reportDueDate: "",
      receivedDate: WufooData?.ReferralDate,
      adjournedDate: WufooData?.AdjournedDate,
      adjournedTime: "",
      court: WufooData?.Court,
      judge: "Unknown",
      reportContactInfo: "",
      appearance: "",
      report: "",
      readyToAssign: false,
      emailSent: false,
      sealed: false,
      billed: false,
      needsCorrectOrder: false,
      caseType: (WufooData?.CaseType722C + WufooData?.CaseTypePvtPay).includes(
        "Private"
      )
        ? "Private"
        : "Public",
      preferredAvailability: "",
      serviceType: [],
      serviceLocation: "",
      supervisedVisits: false,
      therapeuticVisits: false,
      observationEvaluation: false,
      virtualVisit: false,
      sandwichVisits: false,
      homeStudy: false,
      visitingCoach: false,
      parentCoordinator: false,
      supervisedAccess: false,
      dropIns: false,
      advocacy: false,
      other: false,
      unknown: false,
      referralFiles: [
        WufooData.AppointmentDoc,
        WufooData.OOPDoc,
        WufooData.additionalDoc,
        WufooData.petitionDoc,
      ],
    },
    validationSchema: Yup.object({
      caseNumber: Yup.string().required("Case number is required"),
      caseName: Yup.string().required("Case name is required"),
      status: Yup.string().required("Status is required"),
      // receivedDate: Yup.string().required("Received date is required"),
      // adjournedDate: Yup.string().required("Adjourned date is required"),
      hoursLimit: Yup.number().required("Hours limit is required"),
      caseType: Yup.string().required("Case type is required"),
      // editorContent: Yup.string().required("Content is required"),
      // assignedTo: Yup.number().required("Assigned to is required"),
    }),
    onSubmit: (values) => {
      const postData = {
        CaseNumber: values.caseNumber,
        CaseName: values.caseName,
        VoucherNumber: values.voucherNumber,
        AssignedToUserID: values.assignedTo ? values.assignedTo.id : null,
        SecondarySWUserID: values.secondarySW,
        HoursLimit: values.hoursLimit,
        Comments: values.editorContent,
        ReportDueDate: values.reportDueDate ? values.reportDueDate : null,
        ReceivedDate: values.receivedDate ? values.receivedDate : null,
        AdjournedDate: values.adjournedDate ? values.adjournedDate : null,
        AdjournedTime: values.adjournedTime,
        Court: values.court,
        Judge: values.judge,
        ReportContactInfo: values.reportContactInfo,
        Appearance: values.appearance,
        Report: values.report,
        CaseType: values.caseType,
        PreferredAvailability: values.preferredAvailability,
        ServiceType: [
          values.supervisedVisits && "Supervised Visits",
          values.therapeuticVisits && "Therapeutic Visits",
          values.observationEvaluation && "Observation and Evaluation",
          values.virtualVisit && "Virtual Visit",
          values.sandwichVisits && "Sandwich Visits",
          values.homeStudy && "Home Study",
          values.visitingCoach && "Visiting Coach",
          values.parentCoordinator && "Parent Coordinator",
          values.supervisedAccess && "Supervised Access",
          values.dropIns && "Drop Ins",
          values.advocacy && "Advocacy",
          values.other && "Other",
          values.unknown && "Unknown",
        ].filter(Boolean),
        ServiceLocation: values.serviceLocation,
        CreatedByUserID: userDetails?.id,
        Status: values.status,
        DocketIndexNumber: values.docketNumber,
        ReadyToAssign: values.readyToAssign,
        EmailSent: values.emailSent,
        Sealed: values.sealed,
        Billed: values.billed,
        NeedsCorrectOrder: values.needsCorrectOrder,
        referralFiles: values.referralFiles,
      };

      console.log("data to be encrypted ==>", postData);

      const encryptData = EncryptionHelper.encryptData(postData);

      POST("case/referral-to-case", { encryptData })
        .then((resp) => {
          toast.success(resp?.message);
          closeDialogHandler();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.errors);
        });
    },
  });

  return (
    <Stack gap={4} p={4} sx={{ background: theme.palette.background.default }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h4">Convert to case</Typography>
        <Stack sx={{ cursor: "pointer" }} flexDirection={"row"} gap={2}>
          <DarkLightMode />

          <CloseIcon
            fontSize="large"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              // window.history.back();
              closeDialogHandler();
            }}
          />
        </Stack>
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <Grid container rowSpacing={4} columnSpacing={8}>
          {/* 1  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Case Number<span style={{ color: "red" }}>*</span>
              </Typography>
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="caseNumber"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.caseNumber}
                  startIcon={false}
                />
                {formik.touched.caseNumber && formik.errors.caseNumber ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.caseNumber}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 2  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Case Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="caseName"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.caseName}
                  startIcon={false}
                />

                {formik.touched.caseName && formik.errors.caseName ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.caseName}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 3  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Docket/Index Number
              </Typography>
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="docketNumber"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.docketNumber}
                  startIcon={false}
                />
                {formik.touched.docketNumber && formik.errors.docketNumber ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.docketNumber}
                  </div>
                ) : null}{" "}
              </Stack>
            </Stack>
          </Grid>
          {/* 4  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Voucher Number</Typography>
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="voucherNumber"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.voucherNumber}
                  startIcon={false}
                />

                {formik.touched.voucherNumber && formik.errors.voucherNumber ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.voucherNumber}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 5  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Assigned To</Typography>
              <Stack sx={{ width: "100%" }}>
                {/* <SelectInput
                  name="assignedTo"
                  placeholder="Please select"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.assignedTo}
                  dropDownData={assignToDropdown}
                  helperText=""
                /> */}

                <UserSearchDropdown
                  name="assignedTo"
                  value={formik.values.assignedTo}
                  collaboratorsData={formik.values.secondarySW}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("assignedTo", newValue);
                  }}
                  onBlur={formik.handleBlur}
                  reset={resetDropdown}
                />

                {formik.touched.assignedTo && formik.errors.assignedTo ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.assignedTo}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 6  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Collaborator</Typography>

              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="secondarySW"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.secondarySW}
                  dropDownData={swData
                    ?.map((item) => {
                      return {
                        value: item.UserID,
                        label: item.Username,
                      };
                    })
                    .filter(
                      (item) => item?.value !== formik?.values?.assignedTo?.id
                    )}
                  helperText=""
                  isMulti={true}
                />

                {formik.touched.secondarySW && formik.errors.secondarySW ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.secondarySW}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 7  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Status<span style={{ color: "red" }}>*</span>
              </Typography>
              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  disabled={true}
                  name="status"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.status}
                  dropDownData={statusData}
                  helperText=""
                />
                {formik.touched.status && formik.errors.status ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.status}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 8  */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Hours Limit<span style={{ color: "red" }}>*</span>
              </Typography>

              <Stack sx={{ width: "100%" }}>
                <NumberInput
                  name="hoursLimit"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.hoursLimit}
                  startIcon={false}
                  helperText={
                    "Maximum number of hours to bill against (as stated on the court order)"
                  }
                />
                {formik.touched.hoursLimit && formik.errors.hoursLimit ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.hoursLimit}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 9 */}
          <Grid item xs={12}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Comments</Typography>

              <Stack sx={{ width: "100%" }}>
                {/* <TextAreaInput
                  name="comments"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.comments}
                  helperText=""
                />
                {formik.touched.comments && formik.errors.comments ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.comments}
                  </div>
                ) : null} */}

                <RichTextEditor
                  value={editorValue}
                  onChange={handleEditorChange}
                  // You may want to pass other props like modules and formats here
                />
                {formik.touched.editorContent && formik.errors.editorContent ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.editorContent}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 23 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Case Type<span style={{ color: "red" }}>*</span>
              </Typography>

              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="caseType"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.caseType}
                  helperText=""
                  dropDownData={caseTypeData}
                />
                {formik.touched.caseType && formik.errors.caseType ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.caseType}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 11 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Report Due Date
              </Typography>

              <Stack sx={{ width: "100%" }}>
                <DateInput
                  name="reportDueDate"
                  placeholder="reportDueDate"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.reportDueDate}
                  helperText={""}
                />
                {formik.touched.reportDueDate && formik.errors.reportDueDate ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.reportDueDate}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 10 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Received Date</Typography>
              <Stack sx={{ width: "100%" }}>
                <DateInput
                  name="receivedDate"
                  placeholder="receivedDate"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.receivedDate}
                  helperText={""}
                />
                {formik.touched.receivedDate && formik.errors.receivedDate ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.receivedDate}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 12 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Adjourned Date</Typography>

              <Stack sx={{ width: "100%" }}>
                <DateInput
                  name="adjournedDate"
                  placeholder="adjournedDate"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.adjournedDate}
                  helperText={""}
                />
                {formik.touched.adjournedDate && formik.errors.adjournedDate ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.adjournedDate}
                  </div>
                ) : null}{" "}
              </Stack>
            </Stack>
          </Grid>
          {/* 12 */}
          {/* <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Adjourned Time</Typography>

              <Stack sx={{ width: "100%" }}>
                <DateInput
                  type="time"
                  name="adjournedTime"
                  placeholder="Enter time"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.adjournedTime}
                  startIcon={false}
                  helperText=""
                />
                {formik.touched.adjournedTime && formik.errors.adjournedTime ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.adjournedTime}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid> */}
          {/* 13 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Court</Typography>
              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="court"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.court}
                  dropDownData={courtDropdown}
                  showPleaseSelect={false}
                  helperText=""
                />

                {formik.touched.court && formik.errors.court ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.court}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 14 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Judge</Typography>
              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="judge"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.judge}
                  dropDownData={judgeDropdown}
                  showPleaseSelect={false}
                  helperText=""
                />

                {formik.touched.judge && formik.errors.judge ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.judge}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 15 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Report Contact Info
              </Typography>
              <Stack sx={{ width: "100%" }}>
                <TextInput
                  name="reportContactInfo"
                  placeholder="Enter a name or email address..."
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.reportContactInfo}
                  startIcon={false}
                />

                {formik.touched.reportContactInfo &&
                formik.errors.reportContactInfo ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.reportContactInfo}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 16 */}
          {/* <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Appearance</Typography>

              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="appearance"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.appearance}
                  helperText=""
                  dropDownData={appearanceReport}
                />
                {formik.touched.appearance && formik.errors.appearance ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.appearance}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid> */}
          {/* 17 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>Report</Typography>

              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="report"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.report}
                  helperText=""
                  dropDownData={appearanceReport}
                />
                {formik.touched.report && formik.errors.report ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.report}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 18 */}
          <Grid item xs={12} sm={6}>
            <Stack direction={"row"} gap={4}>
              <Typography sx={{ textAlign: "left", width: "25%" }}>
                Ready to Assign?
              </Typography>
              <Stack>
                <CheckboxInput
                  name="readyToAssign"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.readyToAssign}
                  label=""
                  helperText="Check this is the case is ready to be assigned to a worker."
                />
                {formik.touched.readyToAssign && formik.errors.readyToAssign ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.readyToAssign}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 19 */}
          <Grid item xs={12} sm={6}>
            <Stack direction={"row"} gap={4}>
              <Typography sx={{ textAlign: "left", width: "25%" }}>
                Email Sent?{" "}
              </Typography>

              <Stack>
                <CheckboxInput
                  name="emailSent"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.emailSent}
                  label=""
                  helperText=""
                />
                {formik.touched.emailSent && formik.errors.emailSent ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.emailSent}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 20 */}
          <Grid item xs={12} sm={6}>
            <Stack direction={"row"} gap={4}>
              <Typography sx={{ textAlign: "left", width: "25%" }}>
                Sealed{" "}
              </Typography>
              <Stack>
                <CheckboxInput
                  name="sealed"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.sealed}
                  label=""
                  helperText="For Simon's use only."
                />
                {formik.touched.sealed && formik.errors.sealed ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.sealed}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 21 */}
          {(userDetails?.role === 1 || userDetails?.role === 3) && (
            <Grid item xs={12} sm={6}>
              <Stack direction={"row"} gap={4}>
                <Typography sx={{ textAlign: "left", width: "25%" }}>
                  Billed?{" "}
                </Typography>

                <Stack>
                  <CheckboxInput
                    name="billed"
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.billed}
                    label=""
                    helperText="For Billing's Use Only"
                  />
                  {formik.touched.billed && formik.errors.billed ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.billed}
                    </div>
                  ) : null}
                </Stack>
              </Stack>
            </Grid>
          )}
          {/* 22 */}
          <Grid item xs={12} sm={6}>
            <Stack direction={"row"} gap={4}>
              <Typography sx={{ textAlign: "left", width: "25%" }}>
                Needs Correct Order
              </Typography>

              <Stack>
                <CheckboxInput
                  name="needsCorrectOrder"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.needsCorrectOrder}
                  label=""
                  helperText="These cases need a proper 722c order."
                />
                {formik.touched.needsCorrectOrder &&
                formik.errors.needsCorrectOrder ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.needsCorrectOrder}
                  </div>
                ) : null}
              </Stack>
            </Stack>
            {[
              WufooData.AppointmentDoc,
              WufooData.OOPDoc,
              WufooData.additionalDoc,
              WufooData.petitionDoc,
            ].some((file) => file) && (
              <Stack direction={"row"} gap={4} alignItems={"flex-start"} mt={2}>
                <Typography sx={{ textAlign: "left", width: "25%" }}>
                  The following documents will be attached to this case:
                </Typography>

                <Stack direction={"column"} gap={1} sx={{ width: "75%" }}>
                  {[
                    WufooData.AppointmentDoc,
                    WufooData.OOPDoc,
                    WufooData.additionalDoc,
                    WufooData.petitionDoc,
                  ].map((file, index) =>
                    file ? <Typography key={index}>{file}</Typography> : null
                  )}
                </Stack>
              </Stack>
            )}
          </Grid>

          {/* 24 */}
          {/* <Grid item xs={12}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Preferred Availability
              </Typography>

              <Stack sx={{ width: "100%" }}>
                <TextAreaInput
                  name="preferredAvailability"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.preferredAvailability}
                  helperText=""
                />
                {formik.touched.preferredAvailability &&
                formik.errors.preferredAvailability ? (
                  <div
                    style={{
                      fontSize: "smaller",

                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.preferredAvailability}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid> */}
          {/* 25 */}
          <Grid item xs={12} sm={6}>
            <Stack direction={"row"} gap={4}>
              <Typography sx={{ textAlign: "left" }}>Service Type </Typography>
              <Stack>
                <CheckboxInput
                  name="supervisedVisits"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.supervisedVisits}
                  label="Supervised Visits"
                  helperText=""
                />
                {formik.touched.supervisedVisits &&
                formik.errors.supervisedVisits ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.supervisedVisits}
                  </div>
                ) : null}

                <CheckboxInput
                  name="therapeuticVisits"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.therapeuticVisits}
                  label="Therapeutic Visits"
                  helperText=""
                />
                {formik.touched.therapeuticVisits &&
                formik.errors.therapeuticVisits ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.therapeuticVisits}
                  </div>
                ) : null}

                <CheckboxInput
                  name="observationEvaluation"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.observationEvaluation}
                  label="Observation and Evaluation"
                  helperText=""
                />
                {formik.touched.observationEvaluation &&
                formik.errors.observationEvaluation ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.observationEvaluation}
                  </div>
                ) : null}

                <CheckboxInput
                  name="virtualVisit"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.virtualVisit}
                  label="Virtual Visit"
                  helperText=""
                />
                {formik.touched.virtualVisit && formik.errors.virtualVisit ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.virtualVisit}
                  </div>
                ) : null}

                <CheckboxInput
                  name="sandwichVisits"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.sandwichVisits}
                  label="Sandwich Visits"
                  helperText=""
                />
                {formik.touched.sandwichVisits &&
                formik.errors.sandwichVisits ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.sandwichVisits}
                  </div>
                ) : null}

                <CheckboxInput
                  name="homeStudy"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.homeStudy}
                  label="Home Study"
                  helperText=""
                />
                {formik.touched.homeStudy && formik.errors.homeStudy ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.homeStudy}
                  </div>
                ) : null}

                <CheckboxInput
                  name="visitingCoach"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.visitingCoach}
                  label="Visiting Coach"
                  helperText=""
                />
                {formik.touched.visitingCoach && formik.errors.visitingCoach ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.visitingCoach}
                  </div>
                ) : null}

                <CheckboxInput
                  name="parentCoordinator"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.parentCoordinator}
                  label="Parent Coordinator"
                  helperText=""
                />
                {formik.touched.parentCoordinator &&
                formik.errors.parentCoordinator ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.parentCoordinator}
                  </div>
                ) : null}

                <CheckboxInput
                  name="supervisedAccess"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.supervisedAccess}
                  label="Supervised Access"
                  helperText=""
                />
                {formik.touched.supervisedAccess &&
                formik.errors.supervisedAccess ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.supervisedAccess}
                  </div>
                ) : null}

                <CheckboxInput
                  name="dropIns"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.dropIns}
                  label="Drop Ins"
                  helperText=""
                />
                {formik.touched.dropIns && formik.errors.dropIns ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.dropIns}
                  </div>
                ) : null}

                <CheckboxInput
                  name="advocacy"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.advocacy}
                  label="Advocacy"
                  helperText=""
                />
                {formik.touched.advocacy && formik.errors.advocacy ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.advocacy}
                  </div>
                ) : null}

                <CheckboxInput
                  name="other"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.other}
                  label="Other"
                  helperText=""
                />
                {formik.touched.other && formik.errors.other ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.other}
                  </div>
                ) : null}

                <CheckboxInput
                  name="unknown"
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.unknown}
                  label="Unknown"
                  helperText=""
                />
                {formik.touched.unknown && formik.errors.unknown ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.unknown}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
          {/* 26 */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography sx={{ textAlign: "left" }}>
                Service Location
              </Typography>

              <Stack sx={{ width: "100%" }}>
                <SelectInput
                  name="serviceLocation"
                  placeholder=""
                  onChangeValue={formik.handleChange}
                  onBlurValue={formik.handleBlur}
                  value={formik.values.serviceLocation}
                  helperText=""
                  dropDownData={serviceLocationData}
                />
                {formik.touched.serviceLocation &&
                formik.errors.serviceLocation ? (
                  <div
                    style={{
                      fontSize: "smaller",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.serviceLocation}
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={2}
          p={2}
        >
          <ButtonCommon type="submit">Save</ButtonCommon>
          <ButtonCommon
            btnClicked={() => {
              closeDialogHandler();
            }}
          >
            Cancel
          </ButtonCommon>
        </Stack>
      </form>
    </Stack>
  );
};

export default WufooToCase;
