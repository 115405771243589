import { Box } from "@mui/material";
import React from "react";
import MainHeader from "../../shared/MainHeader";
import SuspendedCaseTable from "../../components/SuspendedCaseTable";

const SuspendedCases = () => {
  return (
    <Box>
      <MainHeader headingText={"Suspended Cases"} />
      <SuspendedCaseTable />
    </Box>
  );
};

export default SuspendedCases;
