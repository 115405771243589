import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import MainHeader from "../../shared/MainHeader";
import TextInput from "../../shared/TextInput";
import ButtonCommon from "../../shared/ButtonCommon";
import TextAreaInput from "../../shared/TextAreaInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { POST } from "../../api/ApiService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SingleFileInput from "../../shared/SingleFileInput";
import CaseSearchDropdown from "../../shared/CaseSearchDropdown";

const CreateNote = () => {
  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const [selectedFiles, setSelectedFiles] = useState();
  const [clearFile, setClearFile] = useState(false);
  const [resetDropdown, setResetDropdown] = useState(false);

  // Reset the dropdown reset state after it has been set to true
  useEffect(() => {
    if (resetDropdown) {
      setResetDropdown(false);
    }
  }, [resetDropdown]);

  const handleFileChange = (files) => {
    setClearFile(false);
    setSelectedFiles(files);
  };

  const formik = useFormik({
    initialValues: {
      subject: "",
      case: null,
      note: "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Subject is required"),
      case: Yup.object().required("Case is required"),
      note: Yup.string().required("Note is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      // Create a FormData object
      const formData = new FormData();

      // Append text fields to FormData
      formData.append("Subject", values.subject);
      formData.append("Note", values.note);
      formData.append("CaseID", values.case.CaseID);
      formData.append("CreatedByUserID", userDetails?.id);

      // Append file to FormData
      formData.append("FilePath", selectedFiles);

      POST("case-notes/create", formData, true, true)
        .then((resp) => {
          toast.success(resp.message);
          resetForm();
          setClearFile(true);
          setResetDropdown(true);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
        });
    },
  });

  return (
    <Box>
      <MainHeader headingText="Create Note" />
      <Stack
        p={3}
        gap={4}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            {/* row one */}
            <Grid container item>
              <Grid
                item
                xs={4}
                sm={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography sx={{ textAlign: "left" }}>
                  Subject<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>

              <Grid
                item
                xs={8}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Stack sx={{ width: "100%" }}>
                  <TextInput
                    name="subject"
                    placeholder=""
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.subject}
                    startIcon={false}
                  />

                  {formik.touched.subject && formik.errors.subject ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.subject}
                    </div>
                  ) : null}
                </Stack>
              </Grid>
            </Grid>
            {/* row two */}
            <Grid container item>
              <Grid
                item
                xs={4}
                sm={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography sx={{ textAlign: "left" }}>
                  Case<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>

              <Grid
                item
                xs={8}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Stack sx={{ width: "100%" }}>
                  <CaseSearchDropdown
                    name="case"
                    value={formik.values.case}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("case", newValue);
                    }}
                    onBlur={formik.handleBlur}
                    reset={resetDropdown}
                  />

                  {formik.touched.case && formik.errors.case ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.case}
                    </div>
                  ) : null}
                </Stack>
              </Grid>
            </Grid>
            {/* row two */}
            <Grid container item>
              <Grid
                item
                xs={4}
                sm={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography sx={{ textAlign: "left" }}>
                  Note<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>

              <Grid
                item
                xs={8}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Stack sx={{ width: "100%" }}>
                  <TextAreaInput
                    name="note"
                    placeholder=""
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.note}
                    rows={10}
                    helperText=""
                  />
                  {formik.touched.note && formik.errors.note ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.note}
                    </div>
                  ) : null}
                </Stack>
              </Grid>
            </Grid>
            {/* row four */}
            <Grid container item>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              ></Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <SingleFileInput
                  onFileChange={handleFileChange}
                  clearFile={clearFile}
                />
              </Grid>
            </Grid>

            {/* row three */}
            <Grid container item>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              ></Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <ButtonCommon type="submit">Save</ButtonCommon>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </Box>
  );
};

export default CreateNote;
