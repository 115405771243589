import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import { GET, PUT } from "../../api/ApiService";
import EncryptionHelper from "../../shared/EncryptionHelper";
import { useDispatch, useSelector } from "react-redux";
import { storeAllRoles } from "../../slices/allroleSlice";
import ButtonCommon from "../../shared/ButtonCommon";
import AutocompleteInput from "../../shared/AutocompleteInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import EditIcon from "@mui/icons-material/Edit";
import SearchBar from "../../shared/SearchBar";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import EditUsers from "../../components/EditUser";

const headerConfig = [
  { id: "Username", label: "User Name", sortable: true },
  { id: "Email", label: "Email", sortable: true },
  { id: "RoleName", label: "Role", sortable: true },
  { id: "Status", label: "Status", sortable: true },
  { id: "Nickname", label: "Nickname", sortable: true },
  { id: "Billing Id", label: "Billing Id", sortable: true },
  { id: "Actions", label: "Actions", sortable: false },
];

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    // if (page > 1) {
    onPageChange(event, page - 1);
    // }
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: "2.5rem" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </div>
  );
}

const statusDropdown = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Active",
    value: 1,
  },
  {
    label: "Inactive",
    value: 0,
  },
];

const ManageRoles = () => {
  const dispatch = useDispatch();
  // const theme = useTheme();
  const allRoles = useSelector((state) => state.allroleReducer.allroles);

  const [rolesData, setRolesData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [userDetails, setUserDetails] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  // const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editData, setEditData] = useState();
  const [openEdit, setOpenEdit] = useState(false);

  const handleStatusChange = (data) => {
    const putData = {
      userid: data?.UserID,
      status: data?.status === 1 ? 0 : 1,
    };

    const encryptData = EncryptionHelper.encryptData(putData);

    PUT("change-status", { encryptData })
      .then((resp) => {
        const data = EncryptionHelper.decryptData(resp);
        toast.success(data?.message);
        fetchUsers();
      })
      .catch((err) => {
        const data = EncryptionHelper.decryptData(err);
        toast.error(data?.response?.data?.message);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage]);

  const fetchUsers = () => {
    setLoading(true);

    GET(
      `users?pageNumber=${
        page + 1
      }&pageSize=${rowsPerPage}&role=${roleFilter}&status=${statusFilter}`
    )
      .then((response) => {
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        setData(decryptedData?.users);

        console.log("decryptedData of get users =>", decryptedData);

        setTotalRows(decryptedData?.totalUsers);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const getSearchValue = (value) => {
    setLoading(true);

    GET(
      `users?pageNumber=${1}&pageSize=${rowsPerPage}&role=${roleFilter}&status=${statusFilter}&search=${value}`
    )
      .then((response) => {
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        setData(decryptedData?.users);
        setPage(0);
        console.log("decryptedData of get users =>", decryptedData);

        setTotalRows(decryptedData?.totalUsers);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    GET("roles")
      .then((resp) => {
        const encryptedData = resp?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);
        dispatch(storeAllRoles(decryptedData));
      })
      .catch((err) => {
        console.warn("err ==>", err);
      });
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [statusFilter, roleFilter]);

  useEffect(() => {
    const dropdownRoles = allRoles.map((item) => {
      return {
        value: item.RoleID,
        label: item.RoleName,
      };
    });
    setRolesData(dropdownRoles);
  }, [allRoles]);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const roleClickHandler = (userData) => {
    setUserDetails(userData);
    setOpenDialog(true);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isDateField = (field) => {
    return ["ReceivedDate", "AdjournedDate", "CreatedAt", "UpdatedAt"].includes(
      field
    );
  };

  const sortedData = data.slice().sort((a, b) => {
    if (orderBy) {
      const valueA = a[orderBy] || "";
      const valueB = b[orderBy] || "";

      if (isDateField(orderBy)) {
        const dateA =
          valueA === "null" || valueA === "Invalid date"
            ? null
            : new Date(valueA);
        const dateB =
          valueB === "null" || valueB === "Invalid date"
            ? null
            : new Date(valueB);

        // Handle null values: place them at the end for ascending order and at the start for descending order
        if (dateA === null && dateB === null) return 0;
        if (dateA === null) return order === "asc" ? 1 : -1;
        if (dateB === null) return order === "asc" ? -1 : 1;

        // Handle Invalid dates
        if (isNaN(dateA) && isNaN(dateB)) return 0; // Both are invalid dates
        if (isNaN(dateA)) return order === "asc" ? 1 : -1; // dateA is invalid
        if (isNaN(dateB)) return order === "asc" ? -1 : 1; // dateB is invalid

        return order === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        return order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const editClickHandler = (rowData) => {
    setEditData(rowData);
    setOpenEdit(true);
  };

  const closeEditHandler = () => {
    setOpenEdit(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      allroles: { value: userDetails?.RoleID, label: userDetails?.RoleName },
    },
    validationSchema: Yup.object({
      allroles: Yup.object().required("Roles is required"),
    }),
    onSubmit: (values) => {
      const putData = {
        userid: userDetails?.UserID,
        roleid: values?.allroles?.value,
      };

      console.log("data to be encrypted =>", putData);

      const encryptData = EncryptionHelper.encryptData(putData);

      PUT("update-role", { encryptData })
        .then((resp) => {
          toast.success(resp?.message);
          fetchUsers();
          handleDialogClose();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
        });
    },
  });

  return (
    <Box>
      <MainHeader headingText="Manage Users" />
      <Stack
        gap={3}
        p={3}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        <Stack
          direction={"row"}
          gap={6}
          sx={{ width: "100%" }}
          flexWrap={"wrap"}
        >
          <Stack sx={{ width: "100%", maxWidth: "300px" }}>
            <Typography sx={{ textAlign: "left" }}>Roles</Typography>
            <Stack>
              <AutocompleteInput
                name="allroles"
                options={rolesData}
                onChange={(event, newValue) => {
                  setRoleFilter(newValue?.value);
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ width: "100%", maxWidth: "300px" }}>
            <Typography sx={{ textAlign: "left" }}>Status</Typography>
            <Stack>
              <AutocompleteInput
                name="allroles"
                options={statusDropdown}
                // value={formik.values.allroles}
                onChange={(event, newValue) => {
                  setStatusFilter(newValue?.value);
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ width: "100%", maxWidth: "300px" }}>
            <Typography sx={{ textAlign: "left" }}>Search</Typography>
            <Stack>
              <SearchBar
                searchHeight={"35px"}
                placeholder={"Search by name or email"}
                searchValue={getSearchValue}
              />
            </Stack>
          </Stack>
        </Stack>

        {loading ? (
          <Stack justifyContent={"center"} alignItems={"center"} height={400}>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {sortedData?.length > 0 ? (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headerConfig.map((header) => (
                          <TableCell key={header.id}>
                            {header.sortable ? (
                              <TableSortLabel
                                active={orderBy === header.id}
                                direction={
                                  orderBy === header.id ? order : "asc"
                                }
                                onClick={() => handleRequestSort(header.id)}
                              >
                                {header.label}
                              </TableSortLabel>
                            ) : (
                              header.label
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData.map((row, index) => {
                        return (
                          <TableRow key={index} role="checkbox">
                            <TableCell>{row.Username}</TableCell>
                            <TableCell>{row.Email}</TableCell>
                            <TableCell>{row.RoleName}</TableCell>
                            <TableCell>
                              <>
                                {row.status === 1 ? "Active" : "Inactive"}

                                <Switch
                                  size="small"
                                  checked={row.status === 1 ? true : false}
                                  onChange={() => handleStatusChange(row)}
                                />
                              </>
                            </TableCell>
                            <TableCell>
                              {row.nickname ? row.nickname : "null"}
                            </TableCell>
                            <TableCell>
                              {row.BillingID ? row.BillingID : "null"}
                            </TableCell>

                            <TableCell>
                              <AssignmentIndIcon
                                onClick={() => {
                                  roleClickHandler(row);
                                }}
                                style={{ cursor: "pointer", color: "#01997D" }}
                              />
                              <EditIcon
                                sx={{ cursor: "pointer", ml: 2 }}
                                onClick={() => editClickHandler(row)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 30, 75]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </>
            ) : (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                height={200}
              >
                No User Found
              </Stack>
            )}
          </>
        )}

        <Dialog open={openDialog} onClose={handleDialogClose}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4} p={4}>
              {/* 1  */}
              <Grid item xs={12}>
                <Typography variant="h5">
                  Assign role for <b>{userDetails?.Username}</b>
                </Typography>
              </Grid>

              {/* 2  */}
              <Grid item xs={12}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Roles<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <AutocompleteInput
                      name="allroles"
                      options={rolesData}
                      value={formik.values.allroles}
                      onChange={(event, newValue) => {
                        formik.setFieldValue("allroles", newValue);
                      }}
                      // onInputChange={(event, newInputValue) => {
                      //   searchCase(newInputValue);
                      // }}
                      onBlur={formik.handleBlur}
                      // error={formik.touched.allroles && Boolean(formik.errors.allroles)}
                      // helperText={formik.touched.allroles && formik.errors.allroles}
                    />
                    {formik.touched.allroles && formik.errors.allroles ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.allroles}
                      </div>
                    ) : null}{" "}
                  </Stack>
                </Stack>
              </Grid>

              {/* 3 */}
              <Grid item xs={12}>
                <Stack direction={"row"} gap={2} justifyContent={"flex-end"}>
                  <ButtonCommon type="submit">Assign</ButtonCommon>
                  <ButtonCommon btnClicked={handleDialogClose}>
                    Cancel
                  </ButtonCommon>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Dialog>

        <Dialog open={openEdit} onClose={closeEditHandler}>
          <EditUsers
            editUserData={editData}
            fetchUsers={fetchUsers}
            closeEditHandler={closeEditHandler}
          />
        </Dialog>
      </Stack>
    </Box>
  );
};

export default ManageRoles;
