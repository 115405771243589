import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import { DELETE, GET } from "../../api/ApiService";
import EncryptionHelper from "../../shared/EncryptionHelper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { storeEditOrder } from "../../slices/editOrderSlice";
import moment from "moment";
import Chip from "@mui/material/Chip";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";

let headerConfig = [
  { id: "actions", label: "Actions", sortable: false },
  { id: "addedBy", label: "Created by", sortable: true },
  { id: "CaseName", label: "Case", sortable: true },
  { id: "EstimatedHours", label: "Estimated Hours", sortable: true },
  { id: "Status", label: "Status", sortable: true },
  { id: "CreatedAt", label: "Created At", sortable: true },
  { id: "ReportContactInfo", label: "Report Contact Info", sortable: true },
  { id: "StatusRemarks", label: "Status Remarks", sortable: true },
  { id: "Comment", label: "Comments", sortable: true },
];

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    // if (page > 1) {
    onPageChange(event, page - 1);
    // }
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: "2.5rem" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </div>
  );
}

const OrderRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const permissionsIdArray = userDetails?.permission?.map((item) => {
    return item.permission_id;
  });

  // let filteredColumns = [
  //   {
  //     name: <div>Actions</div>,
  //     cell: (row) => (
  //       <div className="tbl-btns">
  //         <EditIcon
  //           onClick={() => {
  //             navigate("/editorderrequest");
  //             dispatch(storeEditOrder(row));
  //           }}
  //         />
  //       </div>
  //     ),
  //     grow: 1,
  //   },
  //   {
  //     name: <div>Created By</div>,
  //     selector: (row) => row.addedBy,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,
  //   },
  //   {
  //     name: <div>Case</div>,
  //     selector: (row) => row.CaseName,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,
  //     // width: "10%",
  //     // allowOverflow: true,
  //   },

  //   {
  //     name: <div>Estimated Hours</div>,
  //     selector: (row) => row.EstimatedHours,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,

  //     // width: "10%",
  //   },
  //   {
  //     name: <div>Status</div>,
  //     selector: (row) =>
  //       row.Status && (
  //         <Box sx={{ width: "80px" }}>
  //           {row.Status === "New" && (
  //             <Chip label={row.Status} color="primary" sx={{ width: "100%" }} />
  //           )}

  //           {row.Status === "Pending" && (
  //             <Chip label={row.Status} color="warning" sx={{ width: "100%" }} />
  //           )}
  //           {row.Status === "Fulfilled" && (
  //             <Chip label={row.Status} color="success" sx={{ width: "100%" }} />
  //           )}

  //           {row.Status === "Denied" && (
  //             <Chip label={row.Status} color="error" sx={{ width: "100%" }} />
  //           )}
  //         </Box>
  //       ),
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,
  //     // width: "10%",
  //   },
  //   {
  //     name: <div>Created At</div>,
  //     selector: (row) =>
  //       row.CreatedAt ? moment(row.CreatedAt).format("LLL") : "null",
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,

  //     // width: "10%",
  //   },
  //   {
  //     name: <div>Report Contact Info</div>,
  //     selector: (row) => row.ServiceOrActivity,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,

  //     // width: "10%",
  //   },
  //   {
  //     name: <div>Status Remarks</div>,
  //     selector: (row) => row.StatusRemarks,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,

  //     // width: "10%",
  //   },
  //   {
  //     name: <div>Comments</div>,
  //     selector: (row) => (
  //       <div dangerouslySetInnerHTML={{ __html: row.Comment }}></div>
  //     ),
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,

  //     // width: "10%",
  //   },
  // ];

  if (!permissionsIdArray?.includes(25)) {
    headerConfig = headerConfig.filter((item) => item.id !== "actions");
  }

  useEffect(() => {
    fetchDar();
  }, [page, rowsPerPage]);

  const fetchDar = () => {
    setLoading(true);

    GET(
      `supplement-oreder/get-order?pageNumber=${
        page + 1
      }&pageSize=${rowsPerPage}`
    )
      .then((response) => {
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        console.log("decryptedData of supplement order =>", decryptedData);

        setData(decryptedData?.orderList);

        setTotalRows(decryptedData?.totalData);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const deleteBtnHandler = () => {
    const deleteData = {
      ids: selected,
    };

    console.log("data to be encrypted =>", deleteData);

    const encryptData = EncryptionHelper.encryptData(deleteData);

    DELETE(`supplement-oreder/delete`, { encryptData })
      .then((resp) => {
        toast.success(resp?.message);
        setToggleClearRows(!toggledClearRows);
        fetchDar(1);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  //  mui function
  const handleSelectAllClick = (event) => {
    /*
    if (event.target.checked) {
      const newSelected = data.map((n) => n.OrderID);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }*/
  };

  const handleClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isDateField = (field) => {
    return ["ReceivedDate", "AdjournedDate", "CreatedAt", "UpdatedAt"].includes(
      field
    );
  };

  const sortedData = data.slice().sort((a, b) => {
    if (orderBy) {
      const valueA = a[orderBy] || "";
      const valueB = b[orderBy] || "";

      if (isDateField(orderBy)) {
        const dateA =
          valueA === "null" || valueA === "Invalid date"
            ? null
            : new Date(valueA);
        const dateB =
          valueB === "null" || valueB === "Invalid date"
            ? null
            : new Date(valueB);

        // Handle null values: place them at the end for ascending order and at the start for descending order
        if (dateA === null && dateB === null) return 0;
        if (dateA === null) return order === "asc" ? 1 : -1;
        if (dateB === null) return order === "asc" ? -1 : 1;

        // Handle Invalid dates
        if (isNaN(dateA) && isNaN(dateB)) return 0; // Both are invalid dates
        if (isNaN(dateA)) return order === "asc" ? 1 : -1; // dateA is invalid
        if (isNaN(dateB)) return order === "asc" ? -1 : 1; // dateB is invalid

        return order === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        return order === "asc"
          ? String(valueA).localeCompare(String(valueB))
          : String(valueB).localeCompare(String(valueA));
      }
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <MainHeader headingText={"Order Request "} />
      <Stack
        gap={3}
        p={3}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        {selected?.length > 0 && (
          <Stack direction={"row"} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              color="red"
              sx={{
                color: "white",
              }}
              onClick={deleteBtnHandler}
            >
              Delete
            </Button>
          </Stack>
        )}

        {loading ? (
          <Stack justifyContent={"center"} alignItems={"center"} height={400}>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {sortedData?.length > 0 ? (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            indeterminate={
                              selected.length > 0 &&
                              selected.length < data.length
                            }
                            checked={
                              data.length > 0 && selected.length === data.length
                            }
                            onChange={handleSelectAllClick}
                          />
                        </TableCell>

                        {headerConfig.map((header) => (
                          <TableCell key={header.id}>
                            {header.sortable ? (
                              <TableSortLabel
                                active={orderBy === header.id}
                                direction={
                                  orderBy === header.id ? order : "asc"
                                }
                                onClick={() => handleRequestSort(header.id)}
                              >
                                {header.label}
                              </TableSortLabel>
                            ) : (
                              header.label
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData.map((row) => {
                        const isItemSelected =
                          selected.indexOf(row.OrderID) !== -1;
                        return (
                          <TableRow
                            key={row.OrderID}
                            role="checkbox"
                            selected={isItemSelected}
                            onClick={(event) => {
                              // Prevent row click from affecting checkbox selection
                              event.stopPropagation();
                            }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) =>
                                  handleClick(event, row.OrderID)
                                }
                                // Prevent checkbox click from triggering the row click event
                                onClick={(event) => event.stopPropagation()}
                              />
                            </TableCell>
                            <TableCell>
                              <EditIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  navigate("/editorderrequest");
                                  dispatch(storeEditOrder(row));
                                }}
                              />
                            </TableCell>
                            <TableCell>{row.addedBy}</TableCell>
                            <TableCell>{row.CaseName}</TableCell>
                            <TableCell>{row.EstimatedHours}</TableCell>
                            <TableCell>{row.Status}</TableCell>
                            <TableCell>
                              {row.CreatedAt
                                ? moment.utc(row.CreatedAt).local().format("LLL")
                                : "null"}
                            </TableCell>
                            <TableCell>
                              {row.Status && (
                                <Box sx={{ width: "80px" }}>
                                  {row.Status === "New" && (
                                    <Chip
                                      label={row.Status}
                                      color="primary"
                                      sx={{ width: "100%" }}
                                    />
                                  )}

                                  {row.Status === "Pending" && (
                                    <Chip
                                      label={row.Status}
                                      color="warning"
                                      sx={{ width: "100%" }}
                                    />
                                  )}
                                  {row.Status === "Fulfilled" && (
                                    <Chip
                                      label={row.Status}
                                      color="success"
                                      sx={{ width: "100%" }}
                                    />
                                  )}

                                  {row.Status === "Denied" && (
                                    <Chip
                                      label={row.Status}
                                      color="error"
                                      sx={{ width: "100%" }}
                                    />
                                  )}
                                </Box>
                              )}
                            </TableCell>
                            <TableCell>{row.StatusRemarks}</TableCell>
                            <TableCell>{row.Comment}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 30, 75]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </>
            ) : (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                height={200}
              >
                No cases found
              </Stack>
            )}
          </>
        )}
      </Stack>{" "}
    </Box>
  );
};

export default OrderRequest;
