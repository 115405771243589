export const statusData = [
  {
    value: "Admin - Edit reports (supervisor only)",
    label: "Admin - Edit reports (supervisor only)",
  },
  { value: "Admin - Holiday Pay", label: "Admin - Holiday Pay" },
  { value: "Admin - Paid time Off", label: "Admin - Paid time Off" },
  { value: "Admin - Supervision", label: "Admin - Supervision" },
  { value: "Admin - Training", label: "Admin - Training" },
  { value: "Admin - w/ Prior Approval", label: "Admin - w/ Prior Approval" },
  { value: "Court Appearance", label: "Court Appearance" },
  {
    value: "Court Appearance w/ Testimony",
    label: "Court Appearance w/ Testimony",
  },
  {
    value: "Facility Visit & Mtg w/Client",
    label: "Facility Visit & Mtg w/Client",
  },
  { value: "Home Visit (NSNVP)", label: "Home Visit (NSNVP)" },
  { value: "Home Visit (NSO)", label: "Home Visit (NSO)" },
  { value: "Home Visit (NSVP)", label: "Home Visit (NSVP)" },
  { value: "Home Visit /Travel", label: "Home Visit /Travel" },
  { value: "Intake (NS)", label: "Intake (NS)" },
  { value: "Intake (NSNVP)", label: "Intake (NSNVP)" },
  { value: "Intake (NSVP)", label: "Intake (NSVP)" },
  { value: "Intake w/ Child", label: "Intake w/ Child" },
  { value: "Intake w/ Collateral", label: "Intake w/ Collateral" },
  { value: "Intake w/ Collateral (NS)", label: "Intake w/ Collateral (NS)" },
  { value: "Intake w/ Custodian", label: "Intake w/ Custodian" },
  { value: "Intake w/ NVP", label: "Intake w/ NVP" },
  { value: "Intake w/ VP", label: "Intake w/ VP" },
  { value: "Meeting w/ AFC & Child", label: "Meeting w/ AFC & Child" },
  { value: "Meeting w/ Attorney", label: "Meeting w/ Attorney" },
  { value: "Meeting w/ Child", label: "Meeting w/ Child" },
  { value: "Meeting w/ Child (NS)", label: "Meeting w/ Child (NS)" },
  { value: "Meeting w/ Client", label: "Meeting w/ Client" },
  { value: "Meeting w/ Client (NS)", label: "Meeting w/ Client (NS)" },
  { value: "Meeting w/ Collateral", label: "Meeting w/ Collateral" },
  { value: "Meeting w/ Collateral (NS)", label: "Meeting w/ Collateral (NS)" },
  { value: "Meeting w/ NVP", label: "Meeting w/ NVP" },
  { value: "Meeting w/ NVP & Child", label: "Meeting w/ NVP & Child" },
  { value: "Meeting w/ NVP (NS)", label: "Meeting w/ NVP (NS)" },
  { value: "Meeting w/ Other", label: "Meeting w/ Other" },
  { value: "Meeting w/ Other (NS)", label: "Meeting w/ Other (NS)" },
  { value: "Meeting w/ Parent & Child", label: "Meeting w/ Parent & Child" },
  { value: "Meeting w/ Parties", label: "Meeting w/ Parties" },
  { value: "Meeting w/ VP", label: "Meeting w/ VP" },
  { value: "Meeting w/ VP & Child", label: "Meeting w/ VP & Child" },
  { value: "Meeting w/ VP (NS)", label: "Meeting w/ VP (NS)" },
  { value: "Monitor Visitation (NSC)", label: "Monitor Visitation (NSC)" },
  { value: "Monitor Visitation (NSVP)", label: "Monitor Visitation (NSVP)" },
  { value: "Monitor Visitation/Travel", label: "Monitor Visitation/Travel" },
  {
    value: "Observe Interaction (Child/Siblings)",
    label: "Observe Interaction (Child/Siblings)",
  },
  { value: "Obsrv Prnt/Child Int (Bk)", label: "Obsrv Prnt/Child Int (Bk)" },
  { value: "Obsrv Prnt/Child Int (GC)", label: "Obsrv Prnt/Child Int (GC)" },
  {
    value: "Obsrv Prnt/Child Int (NS Other)",
    label: "Obsrv Prnt/Child Int (NS Other)",
  },
  {
    value: "Obsrv Prnt/Child Int (NSNVP)",
    label: "Obsrv Prnt/Child Int (NSNVP)",
  },
  { value: "Obsrv Prnt/Child Int (NSO)", label: "Obsrv Prnt/Child Int (NSO)" },
  {
    value: "Obsrv Prnt/Child Int (NSVP)",
    label: "Obsrv Prnt/Child Int (NSVP)",
  },
  { value: "Obsrv Prnt/Child Int (SI)", label: "Obsrv Prnt/Child Int (SI)" },
  {
    value: "Obsrv Prnt/Child Inter (NSC)",
    label: "Obsrv Prnt/Child Inter (NSC)",
  },
  {
    value: "Obsrv Prnt/Child Int (Community)",
    label: "Obsrv Prnt/Child Int (Community)",
  },
  { value: "Phone Conf w/ Attorney", label: "Phone Conf w/ Attorney" },
  { value: "Phone Conf w/ Child", label: "Phone Conf w/ Child" },
  { value: "Phone Conf w/ Client", label: "Phone Conf w/ Client" },
  { value: "Phone Conf w/ Collateral", label: "Phone Conf w/ Collateral" },
  { value: "Phone Conf w/ NVP", label: "Phone Conf w/ NVP" },
  { value: "Phone Conf w/ Other", label: "Phone Conf w/ Other" },
  { value: "Phone Conf w/ Other (NS)", label: "Phone Conf w/ Other (NS)" },
  { value: "Phone Conf w/ Parties", label: "Phone Conf w/ Parties" },
  { value: "Phone Conf w/ VP", label: "Phone Conf w/ VP" },
  { value: "Prepare for Testimony", label: "Prepare for Testimony" },
  {
    value: "Prepare Letter for Collateral",
    label: "Prepare Letter for Collateral",
  },
  { value: "Prepare Letters to Parties", label: "Prepare Letters to Parties" },
  {
    value: "Prepare Written Correspondence",
    label: "Prepare Written Correspondence",
  },
  { value: "Prepare Written Report", label: "Prepare Written Report" },
  {
    value: "Research Collateral Resources",
    label: "Research Collateral Resources",
  },
  { value: "Review Case Records", label: "Review Case Records" },
  { value: "Supervised Exchange", label: "Supervised Exchange" },
  {
    value: "Supervised Exchange & Debrief Child",
    label: "Supervised Exchange & Debrief Child",
  },
  {
    value: "Supervised Exchange (NSNVP)",
    label: "Supervised Exchange (NSNVP)",
  },
  { value: "Supervised Exchange (NSO)", label: "Supervised Exchange (NSO)" },
  { value: "Supervised Exchange (NSVP)", label: "Supervised Exchange (NSVP)" },
  { value: "Supervised Exchange/Travel", label: "Supervised Exchange/Travel" },
  { value: "Supervised Phone Conf", label: "Supervised Phone Conf" },
  {
    value: "Supervised Phone Conf (NSNVP)",
    label: "Supervised Phone Conf (NSNVP)",
  },
  {
    value: "Supervised Phone Conf (NSO)",
    label: "Supervised Phone Conf (NSO)",
  },
  {
    value: "Supervised Phone Conf (NSVP)",
    label: "Supervised Phone Conf (NSVP)",
  },
  { value: "Supervised Visitation (Bk)", label: "Supervised Visitation (Bk)" },
  { value: "Supervised Visitation (GC)", label: "Supervised Visitation (GC)" },
  {
    value: "Supervised Visitation (NSNVP)",
    label: "Supervised Visitation (NSNVP)",
  },
  {
    value: "Supervised Visitation (NSO)",
    label: "Supervised Visitation (NSO)",
  },
  {
    value: "Supervised Visitation (NSVP)",
    label: "Supervised Visitation (NSVP)",
  },
  { value: "Supervised Visitation (SI)", label: "Supervised Visitation (SI)" },
  {
    value: "Supervised Visitation/Travel",
    label: "Supervised Visitation/Travel",
  },
  {
    value: "Therapeutic Virtual Visitation",
    label: "Therapeutic Virtual Visitation",
  },
  { value: "Therapeutic Visitation", label: "Therapeutic Visitation" },
  {
    value: "Therapeutic Visitation (NS)",
    label: "Therapeutic Visitation (NS)",
  },
  {
    value: "Therapeutic Visitation (NSNVP)",
    label: "Therapeutic Visitation (NSNVP)",
  },
  {
    value: "Therapeutic Visitation (NSO)",
    label: "Therapeutic Visitation (NSO)",
  },
  {
    value: "Therapeutic Visitation (NVP)",
    label: "Therapeutic Visitation (NVP)",
  },
  {
    value: "Therapeutic Visitation/Travel",
    label: "Therapeutic Visitation/Travel",
  },
  { value: "Travel Surcharge", label: "Travel Surcharge" },
  {
    value: "Virtual Observation & Evaluation - Public Case",
    label: "Virtual Observation & Evaluation - Public Case",
  },
  {
    value: "Virtual Observation & Evaluation (NSNVP)",
    label: "Virtual Observation & Evaluation (NSNVP)",
  },
  {
    value: "Virtual Observation & Evaluation (NSVP)",
    label: "Virtual Observation & Evaluation (NSVP)",
  },
  {
    value: "Virtual Supervised Visitation - Private Case",
    label: "Virtual Supervised Visitation - Private Case",
  },
  {
    value: "Virtual Supervised Visitation (NS)",
    label: "Virtual Supervised Visitation (NS)",
  },
];
