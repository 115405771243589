import {
  Box,
  Button,
  Grid,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import DateInput from "../../shared/DateInput";
// import SelectInput from "../../shared/SelectInput";
// import { useSelector } from "react-redux";
import { GET, PUT } from "../../api/ApiService";
import EncryptionHelper from "../../shared/EncryptionHelper";
// import DataTable from "react-data-table-component";
// import moment from "moment";
import CaseSearchDropdown from "../../shared/CaseSearchDropdown";
import moment from "moment";
import UserSearchDropdown from "../../shared/UserSearchDropdown";
import { useSelector } from "react-redux";

const ExportActivities = () => {
  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const [data, setData] = useState([]);
  const [showExport, setShowExported] = useState(true);
  const [totalTime, setTotalTime] = useState();
  const [resetDropdown, setResetDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeBtn, setActiveBtn] = useState("");

  useEffect(() => {
    fetchActivities(
      formik.values.startDate,
      formik.values.endDate,
      formik.values?.case?.CaseID ? formik.values?.case?.CaseID : "",
      formik.values.socialWorker
    );
  }, []);

  // Reset the dropdown reset state after it has been set to true
  useEffect(() => {
    if (resetDropdown) {
      setResetDropdown(false);
    }
  }, [resetDropdown]);

  const fetchActivities = (startDate, endDate, caseid, social) => {
    setLoading(true);
    GET(
      `daily-activity/export-activity-report?startDate=${startDate}&endDate=${endDate}&caseId=${caseid}&socialwId=${social}`
    )
      .then((resp) => {
        const encryptedData = resp?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);
        console.log("decryptedData ===========", decryptedData);
        setLoading(false);
        setData(decryptedData?.result);
        setShowExported(true);

        const timeStrings = decryptedData?.result?.map(
          (item) => item.TimeSpent
        );
        const totalTimeResult = sumTime(timeStrings);
        setTotalTime(totalTimeResult);
      })
      .catch((err) => {
        console.warn("Error fetching activities:", err);

        setLoading(false);
      });
  };

  const exportClickHandler = async () => {
    const reportIds = data?.map((item) => item.ReportID);
    console.log("data to be encrypted =>", reportIds);
    const encryptData = EncryptionHelper.encryptData(reportIds);
    try {
      await PUT(`daily-activity/export-dar`, { encryptData });
      fetchActivities(
        formik.values.startDate,
        formik.values.endDate,
        formik.values?.case?.CaseID ? formik.values?.case?.CaseID : "",
        formik.values.socialWorker
      );
    } catch (err) {
      console.warn("err of export dar ====>", err);
    }
  };

  const viewExportHandler = async () => {
    setActiveBtn("viewExport");
    try {
      const response = await GET("daily-activity/all-exported-dar");
      setShowExported(false);
      setData(response);
    } catch (err) {
      console.warn(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      startDate: (() => {
        const currentDate = new Date();
        const startDate = new Date();
        startDate.setDate(currentDate.getDate() - 30);
        return startDate.toISOString().split("T")[0];
      })(),
      case: null,
      // endDate: new Date().toISOString().split("T")[0],
      endDate: (() => {
        const currentDate = new Date();
        const localDate = new Date(
          currentDate.getTime() - currentDate.getTimezoneOffset() * 60000
        );
        return localDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
      })(),
      socialWorker: null,
    },
    validationSchema: Yup.object({
      startDate: Yup.date().required("Start date is required"),
      endDate: Yup.date()
        .required("End date is required")
        .min(Yup.ref("startDate"), "End date must be after start date"),
    }),
    onSubmit: (values) => {
      fetchActivities(
        values.startDate,
        values.endDate,
        values.case?.CaseID ? values.case.CaseID : "",
        values.socialWorker?.id ? values.socialWorker?.id : null
      ); // Fetch activities based on form values

      setActiveBtn("search");
    },
  });

  // const handleLastPayPeriod = () => {
  //   const currentDate = new Date();
  //   const currentDay = currentDate.getDate();
  //   const currentMonth = currentDate.getMonth();
  //   const currentYear = currentDate.getFullYear();

  //   let StartData;
  //   let EndDate;

  //   if (currentDay <= 15) {
  //     StartData = formatDate(new Date(currentYear, currentMonth - 1, 16));
  //     EndDate = formatDate(new Date(currentYear, currentMonth, 0));
  //   } else {
  //     StartData = formatDate(new Date(currentYear, currentMonth, 1));
  //     EndDate = formatDate(new Date(currentYear, currentMonth, 15));
  //   }

  //   formik.setValues({
  //     ...formik.values,
  //     startDate: StartData,
  //     endDate: EndDate,
  //   });

  //   fetchActivities(
  //     StartData,
  //     EndDate,
  //     formik.values?.case?.CaseID ? formik.values?.case?.CaseID : "",
  //     formik.values?.socialWorker
  //   );
  // };

  // const formatDate = (date) => {
  //   const year = date.getFullYear();
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   const day = date.getDate().toString().padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // };

  // const handleCurrentPayPeriod = () => {
  //   const currentDate = new Date();
  //   const currentDay = currentDate.getDate();
  //   const currentMonth = currentDate.getMonth();
  //   const currentYear = currentDate.getFullYear();

  //   let StartData;
  //   let EndDate;

  //   if (currentDay <= 15) {
  //     StartData = formatDate(new Date(currentYear, currentMonth, 1));
  //     EndDate = formatDate(new Date(currentYear, currentMonth, 15));
  //   } else {
  //     StartData = formatDate(new Date(currentYear, currentMonth, 16));
  //     EndDate = formatDate(new Date(currentYear, currentMonth + 1, 0));
  //   }

  //   formik.setValues({
  //     ...formik.values,
  //     startDate: StartData,
  //     endDate: EndDate,
  //   });

  //   fetchActivities(
  //     StartData,
  //     EndDate,
  //     formik.values?.case?.CaseID ? formik.values?.case?.CaseID : "",
  //     formik.values?.socialWorker
  //   );
  // };

  const downloadCSV = () => {
    // Map the data to include only the fields you want
    const formattedData = data?.map((item) => ({
      "User Name": item.UserName,
      "Time Spent": item.TimeSpent,
      "Transaction Date": item.TransactionDate,
      "Start Time": item.StartTime,
      "Finish Time": item.FinishTime,
      "Service or Activity": item.ServiceOrActivity,
      "Case Name": item.CaseName,
      "Case Number": item.CaseNumber,
      "Created At": item.CreatedAt,
    }));

    // Create CSV header
    const headers = Object.keys(formattedData[0]).join(",") + "\n";

    // Create CSV rows
    const rows = formattedData
      .map((row) => Object.values(row).join(","))
      .join("\n");

    // Combine header and rows
    const csvContent = headers + rows;

    // Create a Blob
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link and trigger download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Reports.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function sumTime(times) {
    let totalMinutes = 0;

    for (const time of times) {
      const [hours, minutes] = time.split(":").map(Number);
      totalMinutes += hours * 60 + minutes; // Convert to total minutes
    }

    // Convert back to HH:MM format
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    // Format to HH:MM, ensuring two digits for minutes
    return `${String(totalHours).padStart(2, "0")}:${String(
      remainingMinutes
    ).padStart(2, "0")}`;
  }

  // const darColums = [
  //   {
  //     name: <div>Timekeeper</div>,
  //     selector: (row) => row.UserName,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 1,
  //   },
  //   {
  //     name: <div>Time Spent</div>,
  //     selector: (row) => row.TimeSpent,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,
  //   },
  //   {
  //     name: <div>Transaction Date</div>,
  //     selector: (row) => moment(row.CreatedAt).format("LLL"),
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,
  //     // width: "10%",
  //     // allowOverflow: true,
  //   },

  //   {
  //     name: <div>Start Time</div>,
  //     selector: (row) => row.StartTime,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,

  //     // width: "10%",
  //   },
  //   {
  //     name: <div>Finish Time</div>,
  //     selector: (row) => row.FinishTime,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,

  //     // width: "10%",
  //   },
  //   {
  //     name: <div>Service/Activity</div>,
  //     selector: (row) => row.ServiceOrActivity,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,

  //     // width: "10%",
  //   },
  //   {
  //     name: <div>Case Name</div>,
  //     selector: (row) => row.CaseName,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,

  //     // width: "10%",
  //   },
  //   {
  //     name: <div>Case Number</div>,
  //     selector: (row) => row.CaseNumber,
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,

  //     // width: "10%",
  //   },
  //   {
  //     name: <div>Created</div>,
  //     selector: (row) =>
  //       row.CreatedAt ? moment(row.CreatedAt).format("LLL") : "null",
  //     sortable: true,
  //     wrap: true,
  //     // grow: 2,

  //     // width: "10%",
  //   },
  // ];

  return (
    <Box>
      <MainHeader headingText="Export Report" />
      <Stack
        gap={3}
        p={3}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        <Stack direction={"row"} gap={3}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "21.92px",
              textAlign: "left",
            }}
          >
            My Activities:
          </Typography>
          {/* <Button
            startIcon={false}
            variant={"outlined"}
            color={"shamrock"}
            // sx={{ color: activeFilter === "last" ? "white" : "black" }}
            onClick={handleLastPayPeriod}
          >
            Last Pay Period
          </Button>
          <Button
            startIcon={false}
            variant={"outlined"}
            color={"shamrock"}
            // sx={{ color: activeFilter === "current" ? "white" : "black" }}
            onClick={handleCurrentPayPeriod}
          >
            Current Pay Period
          </Button> */}
        </Stack>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={5}>
            {/* 1  */}
            <Grid item xs={12} sm={4}>
              <Stack>
                <Typography sx={{ textAlign: "left" }}>
                  Start Date<span style={{ color: "red" }}>*</span>
                </Typography>
                <Stack sx={{ width: "100%" }}>
                  <DateInput
                    name="startDate"
                    placeholder=""
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.startDate}
                    startIcon={false}
                  />
                  {formik.touched.startDate && formik.errors.startDate ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.startDate}
                    </div>
                  ) : null}
                </Stack>
              </Stack>
            </Grid>

            {/* 2  */}
            <Grid item xs={12} sm={6}>
              <Stack>
                <Typography sx={{ textAlign: "left" }}>Case:</Typography>
                <Stack sx={{ width: "100%" }}>
                  <CaseSearchDropdown
                    name="case"
                    value={formik.values.case}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("case", newValue);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.case && formik.errors.case ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.case}
                    </div>
                  ) : null}{" "}
                </Stack>
              </Stack>
            </Grid>

            {/* 3 */}
            <Grid item xs={12} sm={4}>
              <Stack>
                <Typography sx={{ textAlign: "left" }}>
                  End Date:
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Stack sx={{ width: "100%" }}>
                  <DateInput
                    name="endDate"
                    placeholder="receivedDate"
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.endDate}
                    helperText={""}
                  />
                  {formik.touched.endDate && formik.errors.endDate ? (
                    <div
                      style={{
                        fontSize: "smaller",

                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.endDate}
                    </div>
                  ) : null}
                </Stack>
              </Stack>
            </Grid>

            {/* 4 */}
            <Grid item xs={12} sm={6}>
              <Stack>
                <Typography sx={{ textAlign: "left" }}>
                  Collaborator:
                </Typography>
                <Stack sx={{ width: "100%" }}>
                  {/* <SelectInput
                    name="socialWorker"
                    placeholder=""
                    onChangeValue={formik.handleChange}
                    onBlurValue={formik.handleBlur}
                    value={formik.values.socialWorker}
                    dropDownData={allusers?.map((item) => {
                      return {
                        value: item.UserID,
                        label: item.Username,
                      };
                    })}
                    helperText=""
                  /> */}

                  <UserSearchDropdown
                    name="socialWorker"
                    value={formik.values.socialWorker}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("socialWorker", newValue);
                    }}
                    onBlur={formik.handleBlur}
                    reset={resetDropdown}
                  />
                  {formik.touched.socialWorker && formik.errors.socialWorker ? (
                    <div
                      style={{
                        fontSize: "smaller",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors.socialWorker}
                    </div>
                  ) : null}
                </Stack>
              </Stack>
            </Grid>

            {/* 5 */}
            <Grid item xs={12}>
              <Stack gap={2} direction={"row"}>
                <Button
                  type="submit"
                  variant={activeBtn === "search" ? "outlined" : "contained"}
                  color="shamrock"
                >
                  Search
                </Button>

                {userDetails?.role == 3 && (
                  <Button
                    variant={
                      activeBtn === "viewExport" ? "outlined" : "contained"
                    }
                    color="shamrock"
                    onClick={viewExportHandler}
                  >
                    View Exported
                  </Button>
                )}

                {data.length > 0 && showExport && (
                  <Button
                    variant="contained"
                    color="shamrock"
                    sx={{ color: "white" }}
                    onClick={exportClickHandler}
                  >
                    Export
                  </Button>
                )}
              </Stack>
            </Grid>

            {/* 5 */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="shamrock"
                sx={{ color: "white" }}
                onClick={downloadCSV}
              >
                Download as CSV
              </Button>
            </Grid>

            <Grid item xs={12}>
              {/* <DataTable
                // title="Cases"
                columns={darColums}
                data={data}
                progressPending={loading}
                // selectableRows
                // onSelectedRowsChange={handleselectedRowChange}
                // pagination
                // paginationServer
                // paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
                // clearSelectedRows={toggledClearRows}
              /> */}
              {!loading && <ExportActivitiesTable data={data} />}
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" justifyContent={"center"}>
                <Typography>
                  <b>Total Time:</b> <span>{totalTime}</span> hours
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </Box>
  );
};

export default ExportActivities;

const headerConfig = [
  { id: "UserName", label: "Time Keeper", sortable: true },
  { id: "TimeSpent", label: "Time Spent", sortable: true },
  { id: "TransactionDate", label: "Transaction Date", sortable: true },
  { id: "StartTime", label: "Start Time", sortable: true },
  { id: "FinishTime", label: "Finish Time", sortable: true },
  { id: "ServiceOrActivity", label: "Service / Activity", sortable: true },
  { id: "CaseName", label: "Case Name", sortable: true },
  { id: "CaseNumber", label: "Case Number", sortable: true },
  { id: "CreatedAt", label: "Created", sortable: true },
];

const ExportActivitiesTable = ({ data }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isDateField = (field) => {
    return ["ReceivedDate", "AdjournedDate", "CreatedAt", "UpdatedAt"].includes(
      field
    );
  };

  const sortedData = data.slice().sort((a, b) => {
    if (orderBy) {
      const valueA = a[orderBy] || "";
      const valueB = b[orderBy] || "";

      if (isDateField(orderBy)) {
        const dateA =
          valueA === "null" || valueA === "Invalid date"
            ? null
            : new Date(valueA);
        const dateB =
          valueB === "null" || valueB === "Invalid date"
            ? null
            : new Date(valueB);

        // Handle null values: place them at the end for ascending order and at the start for descending order
        if (dateA === null && dateB === null) return 0;
        if (dateA === null) return order === "asc" ? 1 : -1;
        if (dateB === null) return order === "asc" ? -1 : 1;

        // Handle Invalid dates
        if (isNaN(dateA) && isNaN(dateB)) return 0; // Both are invalid dates
        if (isNaN(dateA)) return order === "asc" ? 1 : -1; // dateA is invalid
        if (isNaN(dateB)) return order === "asc" ? -1 : 1; // dateB is invalid

        return order === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        return order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
    }
    return 0;
  });

  return (
    <TableContainer>
      <TableHead>
        <TableRow>
          {headerConfig.map((header) => (
            <TableCell key={header.id}>
              {header.sortable ? (
                <TableSortLabel
                  active={orderBy === header.id}
                  direction={orderBy === header.id ? order : "asc"}
                  onClick={() => handleRequestSort(header.id)}
                >
                  {header.label}
                </TableSortLabel>
              ) : (
                header.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedData?.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{item.UserName}</TableCell>
            <TableCell>{item.TimeSpent}</TableCell>
            <TableCell>
              {item.TransactionDate
                ? moment(item.TransactionDate).format("LL")
                : "null"}
            </TableCell>
            <TableCell>{item.StartTime}</TableCell>
            <TableCell>{item.FinishTime}</TableCell>
            <TableCell>{item.ServiceOrActivity}</TableCell>
            <TableCell>{item.CaseName}</TableCell>
            <TableCell>{item.CaseNumber}</TableCell>
            <TableCell>
              {item.CreatedAt ? moment(item.CreatedAt).format("LL") : "null"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  );
};
