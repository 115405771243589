import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { GET } from "../../api/ApiService";
import EncryptionHelper from "../../shared/EncryptionHelper";

const CaseAdjourn = () => {
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GET(`case/case-adjournment`);
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        const eventList = decryptedData.map((item) => {
          const adjournedDate = new Date(`${item.AdjournedDate}T09:00:00`);
          const endDate = new Date(adjournedDate.getTime() + 60 * 60 * 1000);
          return {
            title: `${item.CaseName} Adjourned`,
            start: adjournedDate,
            end: endDate,
          };
        });
        setEvents(eventList);
      } catch (err) {
        console.warn("Error fetching data:", err);
      }
    };
    fetchData();
  }, []);

  const eventTitleFormatter = (event) => {
    const formattedDate = moment(event.start).format("MMMM Do YYYY, h:mm a");
    return `${event.title} - Adjourned on ${formattedDate}`;
  };

  return (
    <Box>
      <MainHeader headingText="Case Adjournment Calendar" />
      <Stack
        p={isMobile ? 2 : 4}
        gap={2}
        sx={{
          border: "1px solid #C1C1C1",
          marginBottom: "20px",
          borderRadius: "8px",
        }}
      >
        <Typography
          sx={{
            fontSize: isMobile ? "16px" : "19.32px",
            fontWeight: "400",
            lineHeight: "23.53px",
            textAlign: "left",
          }}
        >
          The calendar shows when cases are being adjourned. The cases appear in
          the order in which they are adjourned.
        </Typography>
        <Box
          sx={{
            height: isMobile ? 400 : 500,
            overflowX: "auto",
            "& .rbc-calendar": {
              width: isMobile ? "100%" : "auto",
            },
          }}
        >
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ margin: isMobile ? "0 10px" : "0 20px" }}
            components={{
              event: ({ event }) => <span>{eventTitleFormatter(event)}</span>,
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default CaseAdjourn;
