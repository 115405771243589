import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import TextInput from "../../shared/TextInput";
import ButtonCommon from "../../shared/ButtonCommon";
import TextAreaInput from "../../shared/TextAreaInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GET, POST } from "../../api/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import SingleFileInput from "../../shared/SingleFileInput";
import AllNotesTable from "../../shared/AllNotesTable";
import AllAttachments from "../../shared/AllAttachments";
import AllCourtsTable from "../../shared/AllCourtsTable";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import EncryptionHelper from "../../shared/EncryptionHelper";
import { storeEditCase } from "../../slices/editCaseSlice";
import { statusData } from "../../utils/selectActivity";
import SelectInput from "../../shared/SelectInput";
import DateInput from "../../shared/DateInput";
import FileInput from "../../shared/FileInput";

const CaseDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cid = searchParams.get("CID");
  const userDetails = useSelector((state) => state.userReducer.userDetails);

  const permissionsIdArray = userDetails?.permission?.map((item) => {
    return item.permission_id;
  });

  const [selectedFiles, setSelectedFiles] = useState();
  // const [attachments, setAttachments] = useState([]);
  const [tabValue, setTabValue] = useState("two");
  // const [clearFile, setClearFile] = useState(false);
  const [caseDetails, setCaseDetails] = useState();
  const [billedHours, setBilledHours] = useState();
  const [resetDropdown, setResetDropdown] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    // getAllAttachments();
    getCaseDetails();
    getConsumedTime();
  }, []);
  useEffect(() => {
    if (resetDropdown) {
      setResetDropdown(false);
    }
  }, [resetDropdown]);

  // const getAllAttachments = () => {
  //   GET(`case-notes/casenoteattachments/${cid}`)
  //     .then((resp) => {
  //       setAttachments(resp);
  //     })
  //     .catch((err) => {
  //       toast.error(err?.response?.data?.message);
  //     });
  // };

  const getCaseDetails = () => {
    GET(`case/get-case/${cid}`)
      .then((response) => {
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        console.log("decrypted data of case details ==>", decryptedData);

        setCaseDetails(decryptedData);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const getConsumedTime = () => {
    GET(`daily-activity/total-consumed-time?caseID=${cid}`)
      .then((response) => {
        const encryptedData = response?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);
        setBilledHours(decryptedData);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleFileChange = (files) => {
    // setClearFile(false);
    setSelectedFiles(files);
  };

  const formatLocalDateTime = (date) => {
    const offset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
    const localDate = new Date(date.getTime() - offset);
    return localDate.toISOString().slice(0, 16);
  };

  const formik = useFormik({
    initialValues: {
      subject: "",
      note: "",
      activity: "",
      activityStart: formatLocalDateTime(new Date()),
      activityEnd: formatLocalDateTime(
        new Date(new Date().getTime() + 10 * 60 * 1000)
      ),
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Subject is required"),
      note: Yup.string().required("Note is required"),
      activityStart: Yup.string().required(
        "Activity Start Date & Time is required"
      ),
      activityEnd: Yup.string().required(
        "Activity End Date & Time is required"
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      const postData = {
        CaseID: cid,
        //FinishTime: values.activityEnd,
        Exported: false,
        //ServiceOrActivity: values.activity,
        //StartTime: values.activityStart,
        CreatedByUserID: userDetails?.id,
      };

      console.log("data to be encrypted ==>", postData);

      /*
      if (values.activity) {
        const encryptData = EncryptionHelper.encryptData(postData);

        POST("daily-activity/create", { encryptData })
          .then((resp) => {
            toast.success(resp?.message);
            resetForm();
            setResetDropdown(true);
            setCaseDetails(null);
            setBilledHours(null);
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
          });
      }*/


      const formData = new FormData();

      formData.append("Subject", values.subject);
      // respect line breaks in the Note text
      const formattedNote = values.note.replaceAll('\n', '\n<br>');
      formData.append("Note", formattedNote);
      //formData.append("CaseID", caseDetails?.CaseID);
      formData.append("CaseID", cid);
      formData.append("CreatedByUserID", userDetails?.id);
      if (selectedFiles) {
        Array.from(selectedFiles).forEach((file) => {
          formData.append("FilePath", file); // Append each file
        });
      }

      POST("case-notes/create", formData, true, true)
        .then((resp) => {
          toast.success(resp.message);
          resetForm();
          // setClearFile(true);
        })
        .catch((err) => {
          toast.error(err?.message ?? "Error creating note. Unknown Server error");
        });
    },
  });

  useEffect(() => {
    if (tabValue === "one") {
      const now = new Date();
      const tenMinutesLater = new Date(now.getTime() + 10 * 60 * 1000);

      formik.setFieldValue("activityStart", formatLocalDateTime(now));
      formik.setFieldValue("activityEnd", formatLocalDateTime(tenMinutesLater));
    }
  }, [tabValue]);

  return (
    <Box>
      <MainHeader headingText="Case Details" />

      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        sx={{ border: "1px solid #C1C1C1" }}
        p={2}
      >
        {/* row 2  */}
        <Grid item xs={3} sm={2}>
          <b> Case Name</b>
        </Grid>
        <Grid item xs={9} sm={4}>
          {caseDetails?.CaseName}
        </Grid>
        {/* row 3  */}
        <Grid item xs={3} sm={2}>
          <b> Voucher Number</b>
        </Grid>
        <Grid item xs={9} sm={4}>
          {caseDetails?.VoucherNumber}
        </Grid>
        {/* row 1  */}
        <Grid item xs={3} sm={2}>
          <b> Case Number</b>
        </Grid>
        <Grid item xs={9} sm={4}>
          {caseDetails?.CaseNumber}
        </Grid>

        {/* row 5  */}
        <Grid item xs={4} sm={2}>
          <b> Assigned To</b>
        </Grid>
        <Grid item xs={8} sm={4}>
          {caseDetails?.AssignedToUser}
        </Grid>

        {/* row 3  */}
        <Grid item xs={5} sm={2}>
          <b> Docket/Index Number</b>
        </Grid>
        <Grid item xs={7} sm={4}>
          {caseDetails?.DocketIndexNumber}
        </Grid>

        {/* row 7 */}
        <Grid item xs={3} sm={2}>
          <b> Status</b>
        </Grid>
        <Grid item xs={9} sm={4}>
          <span
            style={{
              color: caseDetails?.Status === "Suspended" ? "red" : "",
              fontWeight: caseDetails?.Status === "Suspended" ? "bold" : "",
              fontSize: caseDetails?.Status === "Suspended" ? "x-large" : "",
              textTransform:
                caseDetails?.Status === "Suspended" ? "uppercase" : "",
            }}
          >
            {caseDetails?.Status}
          </span>
        </Grid>
      </Grid>

      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        textColor="shamrock"
        indicatorColor="shamrock"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#3DCBB1",
          },
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
          },
        }}
      >
        <Tab
          sx={{
            fontSize: "15px",
            "&.Mui-selected": {
              color: "#019479",
              fontSize: "18px",
            },
          }}
          value="one"
          label="Add Note"
          wrapped
        />
        <Tab
          sx={{
            fontSize: "15px",
            "&.Mui-selected": {
              color: "#019479",
              fontSize: "18px",
            },
          }}
          value="two"
          label="Case Details"
        />
        <Tab
          sx={{
            fontSize: "15px",
            "&.Mui-selected": {
              color: "#019479",
              fontSize: "18px",
            },
          }}
          value="three"
          label="Court Reports"
        />
        <Tab
          sx={{
            fontSize: "15px",
            "&.Mui-selected": {
              color: "#019479",
              fontSize: "18px",
            },
          }}
          value="four"
          label="All Notes"
        />
        <Tab
          sx={{
            fontSize: "15px",
            "&.Mui-selected": {
              color: "#019479",
              fontSize: "18px",
            },
          }}
          value="five"
          label="Attachments"
        />
      </Tabs>

      {/* Add note  */}
      {tabValue === "one" && (
        <Stack
          p={4}
          gap={4}
          sx={{
            border: "1px solid #C1C1C1",
            minHeight: "80vh",
          }}
        >
          <Typography variant="h5">Add Note</Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              {/* row one */}
              <Grid container item>
                <Grid
                  item
                  xs={4}
                  sm={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography sx={{ textAlign: "left" }}>
                    Subject<span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Stack sx={{ width: "100%" }}>
                    <TextInput
                      name="subject"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.subject}
                      startIcon={false}
                    />

                    {formik.touched.subject && formik.errors.subject ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.subject}
                      </div>
                    ) : null}
                  </Stack>
                </Grid>
              </Grid>

              {/* row two */}
              <Grid container item>
                <Grid
                  item
                  xs={4}
                  sm={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography sx={{ textAlign: "left" }}>
                    Note<span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Stack sx={{ width: "100%" }}>
                    <TextAreaInput
                      name="note"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.note}
                      rows={12}
                      helperText=""
                    />
                    {formik.touched.note && formik.errors.note ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.note}
                      </div>
                    ) : null}
                  </Stack>
                </Grid>
              </Grid>

              {/* row three - Select Activity */}
              <Grid container item>
                <Grid
                  item
                  xs={4}
                  sm={2}
                  sx={{
                    display: "none",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography sx={{ textAlign: "left" }}>
                    Select Activity
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={6}
                  sx={{
                    display: "none",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Stack sx={{ width: "100%" }}>
                    <SelectInput
                      name="activity"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.activity}
                      dropDownData={statusData}
                      helperText=""
                    />
                    {formik.touched.activity && formik.errors.activity ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.activity}
                      </div>
                    ) : null}
                  </Stack>
                </Grid>
              </Grid>

              {/* row four - Activity Start Date & Time */}
              <Grid container item>
                <Grid
                  item
                  xs={4}
                  sm={2}
                  sx={{
                    display: "none",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography sx={{ textAlign: "left" }}>
                    Activity Start Date & Time
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={6}
                  sx={{
                    display: "none",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Stack sx={{ width: "100%" }}>
                    <DateInput
                      type="datetime-local"
                      name="activityStart"
                      placeholder="activityStart"
                      onChangeValue={(e) => {
                        const selectedDate = e.target.value;
                        formik.setFieldValue("activityStart", selectedDate);
                        formik.setFieldValue("activityEnd", selectedDate); // Set activityEnd to the same value
                      }}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.activityStart}
                      helperText={""}
                    />

                    {formik.touched.activityStart &&
                    formik.errors.activityStart ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.activityStart}
                      </div>
                    ) : null}
                  </Stack>
                </Grid>
              </Grid>

              {/* row five - Activity End Date & Time */}
              <Grid container item>
                <Grid
                  item
                  xs={4}
                  sm={2}
                  sx={{
                    display: "none",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography sx={{ textAlign: "left" }}>
                    Activity End Date & Time
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={6}
                  sx={{
                    display: "none",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Stack sx={{ width: "100%" }}>
                    <DateInput
                      type="datetime-local"
                      name="activityEnd"
                      placeholder="activityEnd"
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.activityEnd}
                      helperText={""}
                    />

                    {formik.touched.activityEnd && formik.errors.activityEnd ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.activityEnd}
                      </div>
                    ) : null}
                  </Stack>
                </Grid>
              </Grid>

              {/* row six - file input */}
              <Grid container item>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                ></Grid>

                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {/* <SingleFileInput
                    onFileChange={handleFileChange}
                    clearFile={clearFile}
                  /> */}
                  <FileInput onFileChange={handleFileChange} />
                </Grid>
              </Grid>

              {/* row seven - Save button */}
              <Grid container item>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                ></Grid>

                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <ButtonCommon type="submit">Save</ButtonCommon>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Stack>
      )}

      {/* Details  */}
      {tabValue === "two" && (
        <Stack
          p={4}
          gap={4}
          sx={{
            border: "1px solid #C1C1C1",
            minHeight: "80vh",
          }}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h5">Details</Typography>

            {permissionsIdArray.includes(21) && (
              <ButtonCommon
                btnClicked={() => {
                  dispatch(storeEditCase(caseDetails));
                  navigate("/editcase");
                }}
              >
                Update
              </ButtonCommon>
            )}
          </Stack>

          <Grid container rowSpacing={1}>
            {/* row 1  */}
            <Grid item xs={3} sm={3}>
              Case Number
            </Grid>
            <Grid item xs={9} sm={9}>
              {caseDetails?.CaseNumber}
            </Grid>
            {/* row 2  */}
            <Grid item xs={3} sm={3}>
              Case Name
            </Grid>
            <Grid item xs={9} sm={9}>
              {caseDetails?.CaseName}
            </Grid>
            {/* row 3  */}
            <Grid item xs={6} sm={3}>
              Docket/Index Number
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.DocketIndexNumber}
            </Grid>
            {/* row 3  */}
            <Grid item xs={3} sm={3}>
              Voucher Number
            </Grid>
            <Grid item xs={9} sm={9}>
              {caseDetails?.VoucherNumber}
            </Grid>
            {/* row 5  */}
            <Grid item xs={5} sm={3}>
              Assigned To
            </Grid>
            <Grid item xs={7} sm={9}>
              {caseDetails?.AssignedToUser}
            </Grid>
            {/* row 6 */}
            <Grid item xs={6} sm={3}>
              Collaborators
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.SecondarySWUser}
              {caseDetails?.sw_user?.map((item, index) => (
                <span key={item.UserID}>
                  {" "}
                  {index === caseDetails?.sw_user?.length - 1 &&
                  caseDetails?.sw_user?.length > 1
                    ? "and"
                    : ""}
                  &nbsp;
                  {item.Username}{" "}
                  {index === caseDetails?.sw_user?.length - 2 ||
                  index === caseDetails?.sw_user?.length - 1
                    ? ""
                    : ","}
                </span>
              ))}
            </Grid>
            {/* row 7 */}
            <Grid item xs={3} sm={3}>
              Status
            </Grid>
            <Grid item xs={9} sm={9}>
              <span
                style={{
                  color: caseDetails?.Status === "Suspended" ? "red" : "",
                  fontWeight: caseDetails?.Status === "Suspended" ? "bold" : "",
                  fontSize:
                    caseDetails?.Status === "Suspended" ? "x-large" : "",
                  textTransform:
                    caseDetails?.Status === "Suspended" ? "uppercase" : "",
                }}
              >
                {caseDetails?.Status}
              </span>
            </Grid>
            {/* row 9 */}
            <Grid item xs={3} sm={3}>
              Hours Limit
            </Grid>

            <Grid item xs={9} sm={9}>
              {caseDetails?.HoursLimit}
            </Grid>

            <Grid item xs={3} sm={3}>
              Hours Remaining
            </Grid>
            <Grid item xs={9} sm={9}>
              {caseDetails?.HoursLimit - billedHours?.billed_hours}
            </Grid>

            {/* row 9 */}
            <Grid item xs={12} sm={3}>
              Comments
            </Grid>
            <Grid item xs={12} sm={9}>
              <div
                dangerouslySetInnerHTML={{ __html: caseDetails?.Comments }}
              />
            </Grid>
            {/* row 10 */}
            <Grid item xs={6} sm={3}>
              Received Date
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.ReceivedDate
                ? moment(caseDetails?.ReceivedDate).format("LL")
                : "null"}
            </Grid>
            {/* row 11 */}
            <Grid item xs={6} sm={3}>
              Adjourned Date
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.AdjournedDate
                ? moment(caseDetails?.AdjournedDate).format("LL")
                : "null"}
            </Grid>
            {/* row 12 */}
            {/* <Grid item xs={3} sm={3}>
              Adjourned Time
            </Grid>
            <Grid item xs={9} sm={9}>
              {moment(caseDetails?.AdjournedTime, "HH:mm:ss").format("hh:mm A")}
            </Grid> */}
            {/* row 13 */}
            <Grid item xs={6} sm={3}>
              Court
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.Court}
            </Grid>
            {/* row 14 */}
            <Grid item xs={6} sm={3}>
              Judge
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.Judge}
            </Grid>
            {/* row 15 */}
            <Grid item xs={6} sm={3}>
              Report Contact Info
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.ReportContactInfo}
            </Grid>
            {/* row 16 */}
            {/* <Grid item xs={3} sm={3}>
              Appearance
            </Grid>
            <Grid item xs={9} sm={9}>
              {caseDetails?.Appearance}
            </Grid> */}
            {/* row 17 */}
            <Grid item xs={6} sm={3}>
              Report
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.Report}
            </Grid>
            {/* row 18 */}
            <Grid item xs={6} sm={3}>
              Ready to Assign?
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.ReadyToAssign === 1 ? "Yes" : "No"}
            </Grid>
            {/* row 19 */}
            <Grid item xs={6} sm={3}>
              Email Sent?
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.EmailSent === 1 ? "Yes" : "No"}
            </Grid>
            {/* row 20 */}
            <Grid item xs={6} sm={3}>
              Sealed
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.Sealed === 1 ? "Yes" : "No"}
            </Grid>
            {/* row 21 */}
            <Grid item xs={6} sm={3}>
              Billed?
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.Billed === 1 ? "Yes" : "No"}
            </Grid>
            {/* row 22 */}
            {/* <Grid item xs={3} sm={3}>
              Needs Correct Order
            </Grid>
            <Grid item xs={9} sm={9}>
              {caseDetails?.NeedsCorrectOrder === 1 ? "Yes" : "No"}
            </Grid> */}
            {/* row 23 */}
            <Grid item xs={6} sm={3}>
              Case Type
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.CaseType}
            </Grid>
            {/* row 24 */}
            <Grid item xs={6} sm={3}>
              Preferred Availability
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.PreferredAvailability}
            </Grid>
            {/* row 25 */}
            <Grid item xs={6} sm={3}>
              Service Type
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.ServiceType}
            </Grid>
            {/* row 26 */}
            <Grid item xs={6} sm={3}>
              Service Location
            </Grid>
            <Grid item xs={6} sm={9}>
              {caseDetails?.ServiceLocation}
            </Grid>
            {/* row 27 */}
            {/* <Grid item xs={3} sm={3}>
              Attachments
            </Grid>
            <Grid item xs={9} sm={9}>
              {attachments.map((item, index) => (
                <span key={index}>
                  <a
                    href={`${process.env.REACT_APP_FILE_UPLOADS}/${item.FileName}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.FileName}
                  </a>

                  <br />
                </span>
              ))}
            </Grid> */}

            <Grid item xs={12} sm={12}>
              Created at &nbsp;{moment(caseDetails?.CreatedAt).format("LLL")}{" "}
              &nbsp; by &nbsp;
              {caseDetails?.CreatedByUser
                ? caseDetails?.CreatedByUser
                : "null"}{" "}
              <br />
              Last modified at &nbsp;
              {moment(caseDetails?.UpdatedAt).format("LLL")} &nbsp; by &nbsp;
              {caseDetails?.ModifiedByUser
                ? caseDetails?.ModifiedByUser
                : "null"}
            </Grid>
          </Grid>
        </Stack>
      )}

      {/* All Court Reports  */}
      {tabValue === "three" && (
        <Stack
          p={4}
          gap={4}
          sx={{
            border: "1px solid #C1C1C1",
            minHeight: "80vh",
          }}
        >
          <AllCourtsTable />
        </Stack>
      )}

      {/* All Case Notes */}
      {tabValue === "four" && (
        <Stack
          p={4}
          gap={4}
          sx={{
            border: "1px solid #C1C1C1",
            minHeight: "80vh",
          }}
        >
          <AllNotesTable />
        </Stack>
      )}

      {/* Attachments  */}
      {tabValue === "five" && (
        <Stack
          p={4}
          gap={4}
          sx={{
            border: "1px solid #C1C1C1",
            minHeight: "80vh",
          }}
        >
          <AllAttachments />
        </Stack>
      )}
    </Box>
  );
};

export default CaseDetails;
