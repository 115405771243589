import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import ButtonCommon from "../../shared/ButtonCommon";
import SelectInput from "../../shared/SelectInput";
import DateInput from "../../shared/DateInput";
import { useSelector } from "react-redux";
import { GET, POST } from "../../api/ApiService";
import EncryptionHelper from "../../shared/EncryptionHelper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CaseSearchDropdown from "../../shared/CaseSearchDropdown";
import TextInput from "../../shared/TextInput";
import TextAreaInput from "../../shared/TextAreaInput";
// import SingleFileInput from "../../shared/SingleFileInput";

const CreateNewDar = () => {
  const userDetails = useSelector((state) => state.userReducer.userDetails);
  const [resetDropdown, setResetDropdown] = useState(false);
  const [caseDetails, setCaseDetails] = useState();
  const [billedHours, setBilledHours] = useState(0.0);
  // const [selectedFiles, setSelectedFiles] = useState();
  // const [clearFile, setClearFile] = useState(false);
  const [activityDropdown, setActvityDropdwon] = useState();

  useEffect(() => {
    if (resetDropdown) {
      setResetDropdown(false);
    }
  }, [resetDropdown]);

  // const handleFileChange = (files) => {
  //   setClearFile(false);
  //   setSelectedFiles(files);
  // };

  useEffect(() => {
    getActivityList();
  }, []);

  const getActivityList = async () => {
    try {
      const response = await GET("daily-activity/activity-list");
      response.sort((a, b) => a.value.localeCompare(b.value));
      setActvityDropdwon(response);
    } catch (err) {
      console.error("Error fetching activity list:", err?.response?.data);
      toast.error(err?.response?.data?.message);
    }
  };

  const getConsumedTime = (newValue) => {
    if (newValue) {
      GET(`daily-activity/total-consumed-time?caseID=${newValue?.CaseID}`)
        .then((response) => {
          const encryptedData = response?.encryptedData;
          const decryptedData = EncryptionHelper.decryptData(encryptedData);
          setBilledHours(0);
          if (decryptedData) {
            setBilledHours(Number(decryptedData.billed_hours).toFixed(2));
          }
          
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      case: null,
      activity: "",
      activityStart: "",
      activityEnd: "",
      subject: "",
      note: "",
    },
    validationSchema: Yup.object({
      case: Yup.object().required("Case is required"),
      activity: Yup.string().required("Activity is required"),
      activityStart: Yup.date()
        .required("Start date is required")
        .test(
          "is-within-24-hours",
          "Activity cannot be longer than 12 hours.",
          function (value) {
            const { activityEnd } = this.parent;
            return activityEnd
              ? value >=
              new Date(activityEnd).setHours(
                new Date(activityEnd).getHours() - 12
              )
              : true;
          }
        ),
      activityEnd: Yup.date()
        .required("End date is required")
        .min(Yup.ref("activityStart"), "End date must be after start date"),
    }),

    onSubmit: (values, { resetForm }) => {
      if (caseDetails?.Status === "Closed") {
        toast.error(
          "This case is closed. Unable to create Daily Activity Report."
        );
        return;
      }

      if (caseDetails?.Status === "Suspended") {
        toast.error(
          "Unable to save Daily Activity Report because this case is suspended. Please refrain from performing any further work on this case and contact the Billing team to resolve the issue."
        );
        return;
      }

      if (caseDetails?.Status === "Suspended") {
        toast.error(
          "Unable to save Daily Activity Report because this case is suspended. Please refrain from performing any further work on this case and contact the Billing team to resolve the issue."
        );
        return;
      }

      const timeDifference = calculateTimeDifference(
        values.activityStart,
        values.activityEnd
      );

      const remainingHours =
        caseDetails?.HoursLimit - billedHours;

      if (timeDifference > remainingHours && caseDetails?.HoursLimit != null) {
        toast.warning(
          `The activity duration ${timeDifference.toFixed(
            2
          )} hours exceeds the allowed hours limit.`
        );
        return;
      }
      if (remainingHours < 10) {
        toast.warning("You have hours remaining less than 10.");
        // return;
      }

      const { lastPeriodStartDate, lastPeriodEndDate } = handleLastPayPeriod();

      console.log("{ lastPeriodStartDate, lastPeriodEndDate } ", {
        lastPeriodStartDate,
        lastPeriodEndDate,
      });

      // Don't allow billing activities earlier than the last pay period start date.
      if (values.activityEnd < lastPeriodStartDate) {
        toast.error(
          "Error: unable to create Daily Activity Report because the start/end time are in a previous pay period."
        );
        return;
      }

      const postData = {
        CaseID: values?.case?.CaseID,
        FinishTime: values.activityEnd,
        Exported: false,
        ServiceOrActivity: values.activity,
        StartTime: values.activityStart,
        CreatedByUserID: userDetails?.id,
        Subject: values.subject,
        Note: values.note,
      };

      console.log("data to be encrypted ==>", postData);

      const encryptData = EncryptionHelper.encryptData(postData);

      POST("daily-activity/create", { encryptData })
        .then((resp) => {
          toast.success(resp?.message);
          resetForm();
          setResetDropdown(true);
          setCaseDetails(null);
          setBilledHours(null);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    },
  });

  function calculateTimeDifference(startTime, finishTime) {
    const start = new Date(startTime);
    const finish = new Date(finishTime);
    const differenceInMilliseconds = finish - start;
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    return differenceInHours;
  }

  const handleLastPayPeriod = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let lastPeriodStartDate;
    let lastPeriodEndDate;

    if (currentDay <= 15) {
      lastPeriodStartDate = formatDate(
        new Date(currentYear, currentMonth - 1, 16)
      );
      lastPeriodEndDate = formatDate(new Date(currentYear, currentMonth, 0));
    } else {
      lastPeriodStartDate = formatDate(new Date(currentYear, currentMonth, 1));
      lastPeriodEndDate = formatDate(new Date(currentYear, currentMonth, 15));
    }

    return { lastPeriodStartDate, lastPeriodEndDate };
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // const handleCurrentPayPeriod = () => {
  //   const currentDate = new Date();
  //   const currentDay = currentDate.getDate();
  //   const currentMonth = currentDate.getMonth();
  //   const currentYear = currentDate.getFullYear();

  //   let StartData;
  //   let EndDate;

  //   if (currentDay <= 15) {
  //     StartData = formatDate(new Date(currentYear, currentMonth, 1));
  //     EndDate = formatDate(new Date(currentYear, currentMonth, 15));
  //   } else {
  //     StartData = formatDate(new Date(currentYear, currentMonth, 16));
  //     EndDate = formatDate(new Date(currentYear, currentMonth + 1, 0));
  //   }

  //   formik.setValues({
  //     ...formik.values,
  //     startDate: StartData,
  //     endDate: EndDate,
  //   });

  //   fetchActivities(
  //     StartData,
  //     EndDate,
  //     formik.values?.case?.CaseID ? formik.values?.case?.CaseID : "",
  //     formik.values?.socialWorker
  //   );
  // };

  return (
    <Box>
      <MainHeader headingText="Create New DAR" />
      <Stack
        p={3}
        gap={4}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        <Stack gap={4}>
          <Stack gap={1}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "21.92px",
                textAlign: "left",
              }}
            >
              Create Daily Activity Report
            </Typography>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "18.27px",
                textAlign: "left",
              }}
            >
              ⓘ Select the case name, activity, set the start and end
              dates/times, and press submit.
            </Typography>
          </Stack>

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              {/* Existing Field 1 */}
              <Grid item xs={12} sm={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Case<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <CaseSearchDropdown
                      name="case"
                      value={formik.values.case}
                      onChange={(event, newValue) => {
                        setCaseDetails(newValue);
                        formik.setFieldValue("case", newValue);
                        getConsumedTime(newValue);
                      }}
                      onBlur={formik.handleBlur}
                      reset={resetDropdown}
                      filtered={true}
                    />
                    {formik.touched.case && formik.errors.case ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.case}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* New Field: Subject */}
              {/* <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Subject<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <TextInput
                      name="subject"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.subject}
                      startIcon={false}
                    />
                    {formik.touched.subject && formik.errors.subject ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.subject}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid> */}

              {/* Existing Field 2 */}
              <Grid item xs={12} sm={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Select Activity<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <SelectInput
                      name="activity"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.activity}
                      dropDownData={activityDropdown}
                      helperText=""
                    />
                    {formik.touched.activity && formik.errors.activity ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.activity}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* New Field: Note */}
              {/* <Grid item xs={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Note<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <TextAreaInput
                      name="note"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.note}
                      rows={10}
                      helperText=""
                    />
                    {formik.touched.note && formik.errors.note ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.note}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid> */}

              {/* Existing Fields (Activity Start/End) */}
              <Grid item xs={12} sm={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Activity Start Date & Time
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <DateInput
                      type="datetime-local"
                      name="activityStart"
                      placeholder="activityStart"
                      onChangeValue={(e) => {
                        const selectedDate = e.target.value;
                        formik.setFieldValue("activityStart", selectedDate);
                        formik.setFieldValue("activityEnd", selectedDate); // Set activityEnd to the same value
                      }}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.activityStart}
                      helperText=""
                    />
                    {formik.touched.activityStart &&
                      formik.errors.activityStart ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.activityStart}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>
                    Activity End Date & Time
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Stack sx={{ width: "100%" }}>
                    <DateInput
                      type="datetime-local"
                      name="activityEnd"
                      placeholder="activityEnd"
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.activityEnd}
                      helperText=""
                    />
                    {formik.touched.activityEnd && formik.errors.activityEnd ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.activityEnd}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>Subject</Typography>
                  <Stack sx={{ width: "100%" }}>
                    <TextInput
                      name="subject"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.subject}
                      startIcon={false}
                    />
                    {formik.touched.subject && formik.errors.subject ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.subject}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack>
                  <Typography sx={{ textAlign: "left" }}>Note</Typography>
                  <Stack sx={{ width: "100%" }}>
                    <TextAreaInput
                      name="note"
                      placeholder=""
                      onChangeValue={formik.handleChange}
                      onBlurValue={formik.handleBlur}
                      value={formik.values.note}
                      rows={10}
                      helperText=""
                    />
                    {formik.touched.note && formik.errors.note ? (
                      <div
                        style={{
                          fontSize: "smaller",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.note}
                      </div>
                    ) : null}
                  </Stack>
                </Stack>
              </Grid>

              {/* <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <SingleFileInput
                  onFileChange={handleFileChange}
                  clearFile={clearFile}
                />
              </Grid> */}

              {/* Billing Information (If Available) */}
              {caseDetails && (
                <Grid item xs={12}>
                  <Stack>
                    <Typography sx={{ textAlign: "left" }}>
                      Billing hours limit for this Case:{" "}
                      {caseDetails?.HoursLimit ?? 0}
                    </Typography>
                    <Typography sx={{ textAlign: "left" }}>
                      Total hours billed: {billedHours}
                    </Typography>
                    <Typography sx={{ textAlign: "left" }}>
                      Hours remaining:{" "}
                      {caseDetails?.HoursLimit - billedHours}
                    </Typography>
                  </Stack>
                </Grid>
              )}

              {/* Save Button */}
              <Grid item xs={12}>
                <ButtonCommon type="submit">Save</ButtonCommon>
              </Grid>
            </Grid>
          </form>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CreateNewDar;
