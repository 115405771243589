import React, { useEffect, useState } from "react";
import MainHeader from "../../shared/MainHeader";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { GET, POST } from "../../api/ApiService";
import { useDispatch, useSelector } from "react-redux";
import AutocompleteInput from "../../shared/AutocompleteInput";
import EncryptionHelper from "../../shared/EncryptionHelper";
import { storeAllRoles } from "../../slices/allroleSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Permissions = () => {
  const dispatch = useDispatch();
  const allRoles = useSelector((state) => state.allroleReducer.allroles);

  const [permissions, setPermissions] = useState();
  const [rolesData, setRolesData] = useState([]);
  const [selectedRole, setSelectedRole] = useState();

  useEffect(() => {
    GET("roles")
      .then((resp) => {
        const encryptedData = resp?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        dispatch(storeAllRoles(decryptedData));
      })
      .catch((err) => {
        console.warn("err ==>", err);
      });
  }, []);

  useEffect(() => {
    const dropdownRoles = allRoles?.map((item) => {
      return {
        value: item.RoleID,
        label: item.RoleName,
      };
    });
    setRolesData(dropdownRoles);
  }, [allRoles]);

  useEffect(() => {
    getPermissionForRoles();
  }, [selectedRole]);

  const getPermissionForRoles = () => {
    GET(`assigned-permission/${selectedRole?.value}`)
      .then((resp) => {
        const encryptedData = resp?.encryptedData;
        const decryptedData = EncryptionHelper.decryptData(encryptedData);

        console.log("decryptedData of assigned-permission ==>", decryptedData);
        setPermissions(decryptedData);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const switchHandler = (permissionId) => {
    const postData = {
      role: selectedRole?.value,
      permission: permissionId,
    };

    console.log("data to be encrypted =>", postData);

    const encryptData = EncryptionHelper.encryptData(postData);

    POST("assign-permission", { encryptData })
      .then((resp) => {
        getPermissionForRoles();
        toast.success(resp?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <Box>
      <MainHeader headingText="Permissions" />
      <Stack
        gap={3}
        p={3}
        sx={{
          border: "1px solid #C1C1C1",
          minHeight: "80vh",
        }}
      >
        <Typography variant="h4">Assign Permissions to Role </Typography>

        <AutocompleteInput
          name="allroles"
          options={rolesData}
          // value={formik.values.allroles}
          onChange={(event, newValue) => {
            setSelectedRole(newValue);
          }}
        />

        {selectedRole &&
          permissions?.map((item) => (
            <FormGroup aria-label="position" row key={item.permission_id}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={item.status === "1" ? true : false}
                    onChange={() => switchHandler(item.permission_id)}
                  />
                }
                label={`${item.permission_name} (${item.description})`}
                labelPlacement="end"
              />
            </FormGroup>
          ))}
      </Stack>
    </Box>
  );
};

export default Permissions;
