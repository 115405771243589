import React, { useEffect, useState } from "react";
import { GET } from "../../api/ApiService";
import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import MainHeader from "../../shared/MainHeader";

import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";

import WufooToCase from "../../components/WufooToCase";
import { storeWufooCase } from "../../slices/wufooCaseSlice";
import DownloadIcon from "@mui/icons-material/Download";
import PreviewIcon from "@mui/icons-material/Preview";
import moment from "moment";

const PrivateReferralsPage = () => {
  const [wufooData, setWufooData] = useState();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [resetDropdown, setResetDropdown] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getWufooData();
  }, [page, rowsPerPage]);

  function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      // if (page > 1) {
      onPageChange(event, page - 1);
      // }
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div style={{ flexShrink: 0, marginLeft: "2.5rem" }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getWufooData = async () => {
    try {
      setLoading(true);
      const resp = await GET(
        `wufoo/get-data?pageNumber=${
          page + 1
        }&pageSize=${rowsPerPage}&type=${"private"}`
      );
      setWufooData(resp?.wufooData);
      setLoading(false);
      setTotalRows(resp?.totalWufooData);
    } catch (err) {
      console.warn(err);
      setLoading(false);
    }
  };

  const convertToCaseHandler = (data) => {
    setOpenDialog(true);
    dispatch(storeWufooCase(data));
  };

  const closeDialogHandler = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (resetDropdown) {
      setResetDropdown(false);
    }
  }, [resetDropdown]);

  const downloadFile = async (filename) => {
    try {
      // Step 1: Get SAS URL from backend
      const response = await GET(
        `get-file-url?filename=${encodeURIComponent(filename)}`
      );

      const fileUrl = response?.url;
      if (!fileUrl) {
        throw new Error("Invalid file URL received from server");
      }

      // Step 2: Fetch the file as a blob to force download
      const fileResponse = await fetch(fileUrl);
      const blob = await fileResponse.blob();

      // Step 3: Create a temporary download link
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename || fileUrl.split("/").pop().split("?")[0]; // Extract filename if not provided
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const previewBtnHandler = async (filename) => {
    try {
      // Step 1: Get SAS URL from backend
      const response = await GET(
        `get-file-url?filename=${encodeURIComponent(filename)}`
      );

      const fileUrl = response?.url;

      if (!fileUrl) {
        throw new Error("Invalid file URL received from server");
      }
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div>
      <MainHeader headingText={"Private Referrals"} />

      {loading ? (
        <Stack justifyContent={"center"} alignItems={"center"} height={400}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {wufooData?.length > 0 ? (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Actions</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Referral Date</TableCell>
                      <TableCell>Case Name</TableCell>
                      <TableCell>Court</TableCell>
                      <TableCell>Judge/Referee</TableCell>
                      <TableCell>File Number</TableCell>
                      <TableCell>Adjourn Date</TableCell>
                      <TableCell>Payment Responsibility</TableCell>
                      <TableCell>Visiting Party Name</TableCell>
                      <TableCell>VP Payment Responsibility</TableCell>
                      <TableCell>VP Email</TableCell>
                      <TableCell>VP Phone</TableCell>
                      <TableCell>VP Attorney Name</TableCell>
                      <TableCell>VP Attorney Email</TableCell>
                      <TableCell>VP Attorney Phone</TableCell>
                      <TableCell>CP Name</TableCell>
                      <TableCell>CP Payment Responsibility</TableCell>
                      <TableCell>CP Email</TableCell>
                      <TableCell>CP Phone</TableCell>
                      <TableCell>CP Attorney Name</TableCell>
                      <TableCell>CP Attorney Email</TableCell>
                      <TableCell>CP Attorney Phone</TableCell>
                      <TableCell>Order of Protection</TableCell>
                      <TableCell>OOP Doc</TableCell>
                      <TableCell>Appointment Doc</TableCell>
                      <TableCell>Petition Doc</TableCell>
                      <TableCell>Additional Doc</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {wufooData?.map((item) => (
                      <TableRow key={item.WufooID}>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() => convertToCaseHandler(item)}
                          >
                            Convert to case
                          </Button>
                        </TableCell>
                        <TableCell>{item.Name}</TableCell>
                        <TableCell>{item.PhoneNumber}</TableCell>
                        <TableCell>{item.Email}</TableCell>
                        <TableCell>
                          {item.ReferralDate
                            ? moment(item.ReferralDate).format("LL")
                            : "null"}
                        </TableCell>
                        <TableCell>{item.CaseName}</TableCell>
                        <TableCell>{item.Court}</TableCell>
                        <TableCell>{item.JudgeReferral}</TableCell>
                        <TableCell>{item.FileNumber}</TableCell>
                        <TableCell>{item.AdjournedDate}</TableCell>
                        <TableCell>{item.PaymentResponsibility}</TableCell>
                        <TableCell>{item.VisitingPartyName}</TableCell>
                        <TableCell>{item.VPPaymentResponsibility}</TableCell>
                        <TableCell>{item.VPEmail}</TableCell>
                        <TableCell>{item.VPPhone}</TableCell>
                        <TableCell>{item.VPAttoneyName}</TableCell>
                        <TableCell>{item.VPAttoneyEmail}</TableCell>
                        <TableCell>{item.VPAttoneyPhone}</TableCell>
                        <TableCell>{item.CPName}</TableCell>
                        <TableCell>{item.CPPaymentResponsibility}</TableCell>
                        <TableCell>{item.CPEmail}</TableCell>
                        <TableCell>{item.CPPhone}</TableCell>
                        <TableCell>{item.CPAttoneyName}</TableCell>
                        <TableCell>{item.CPAttoneyEmail}</TableCell>
                        <TableCell>{item.CPAttoneyPhone}</TableCell>
                        <TableCell>{item.OrderOfProtection}</TableCell>
                        <TableCell>
                          {item.OOPDoc ? (
                            <Stack
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <PreviewIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => previewBtnHandler(item.OOPDoc)}
                              />

                              <DownloadIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => downloadFile(item.OOPDoc)}
                              />
                            </Stack>
                          ) : (
                            <Stack
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              null
                            </Stack>
                          )}
                        </TableCell>
                        <TableCell>
                          {item.AppointmentDoc ? (
                            <Stack
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <PreviewIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  previewBtnHandler(item.AppointmentDoc)
                                }
                              />

                              <DownloadIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  downloadFile(item.AppointmentDoc)
                                }
                              />
                            </Stack>
                          ) : (
                            <Stack
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              null
                            </Stack>
                          )}
                        </TableCell>
                        <TableCell>
                          {item.petitionDoc ? (
                            <Stack
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <PreviewIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  previewBtnHandler(item.petitionDoc)
                                }
                              />

                              <DownloadIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => downloadFile(item.petitionDoc)}
                              />
                            </Stack>
                          ) : (
                            <Stack
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              null
                            </Stack>
                          )}
                        </TableCell>
                        <TableCell>
                          {item.additionalDoc ? (
                            <Stack
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <PreviewIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  previewBtnHandler(item.additionalDoc)
                                }
                              />

                              <DownloadIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => downloadFile(item.additionalDoc)}
                              />
                            </Stack>
                          ) : (
                            <Stack
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              null
                            </Stack>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 30, 75]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </>
          ) : (
            <Stack justifyContent={"center"} alignItems={"center"} height={200}>
              No Data Found
            </Stack>
          )}
        </>
      )}

      {/* convert to case dialog  */}
      <Dialog
        open={openDialog}
        fullScreen
        onClose={closeDialogHandler}
        sx={{
          "& .MuiDialog-paper": {
            // maxWidth: "1200px",
          },
        }}
      >
        <WufooToCase closeDialogHandler={closeDialogHandler} />
      </Dialog>
    </div>
  );
};

export default PrivateReferralsPage;
