import * as React from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/Login/index";
import Signup from "../pages/Signup/index";
import ForgotPassword from "../pages/ForgotPassword/index";
import NotFound from "../shared/NotFoundPage/index";
import PrivateRoutes from "./Private.route";

// from mui
import CircularProgress from "@mui/material/CircularProgress";

// pages
import DashboardPage from "../pages/DashboardPage/index";
import CasePage from "../pages/CasePage/index";
import OpenCases from "../pages/OpenCases/index";
import ClosedCases from "../pages/ClosedCases/index";
import PvtCase from "../pages/PvtCase/index";
import PublicCase from "../pages/PublicCase/index";
import CaseAdjourn from "../pages/CaseAdjourn/index";
import PvtcaseonHold from "../pages/PvtcaseonHold/index";
import OpennewCase from "../pages/OpennewCase/index";
import CreateCourtReport from "../pages/CreateCourtReport";
import CreateNote from "../pages/CreateNote";
import CreateNewDar from "../pages/CreateNewDAR";
import ExportActivities from "../pages/ExportActivities";
import SharedDoc from "../pages/SharedDoc";
import ClientForm from "../pages/ClientForm";
import MyDar from "../pages/MyDar";
import RequestSuppOrder from "../pages/RequestSuppOrder";
import OrderRequest from "../pages/OrderRequest";
import AddCase from "../components/AddCase";
import EditCase from "../components/EditCase";
import Profile from "../pages/Profile";
import PubcaseonHold from "../pages/PubcaseonHold";
import CaseDetails from "../pages/CaseDetails";
import EditDar from "../shared/EditDar";
import EditOrderRequest from "../shared/EditOrderRequest";
import ManageRoles from "../pages/ManageRoles";
import Permissions from "../pages/Permissions";
import UpdateNote from "../pages/UpdateNote";
import Audit from "../pages/Audit";
import Pubpvtcaseonhold from "../pages/Pubpvtcaseonhold";
import ReferralsPage from "../pages/ReferralsPage";
import PrivateReferralsPage from "../pages/PrivateReferralsPage";
import SuspendedCases from "../pages/SuspendedCases";

const Loading = () => <CircularProgress />;

const MainRoutes = () => {
  return (
    <React.Suspense fallback={<Loading />}>
      <HashRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<DashboardPage />} path="/dashboard" />
            <Route element={<CasePage />} path="/cases" />
            <Route element={<AddCase />} path="/addcase" />
            <Route element={<EditCase />} path="/editcase" />
            <Route element={<CaseDetails />} path="/casedetails" />
            <Route element={<OpenCases />} path="/opencases" />
            <Route element={<ClosedCases />} path="/closedcases" />
            <Route element={<SuspendedCases />} path="/suspendedcases" />
            <Route element={<PvtCase />} path="/pvtcase" />
            <Route element={<PublicCase />} path="/publiccase" />
            <Route element={<PvtcaseonHold />} path="/caseonhold" />
            <Route element={<PubcaseonHold />} path="/pubcaseonhold" />
            <Route element={<Pubpvtcaseonhold />} path="/pubpvtcaseonhold" />
            <Route element={<OpennewCase />} path="/opennewcase" />
            <Route element={<CaseAdjourn />} path="/caseadjournment" />
            <Route element={<ReferralsPage />} path="/referrals" />
            <Route
              element={<PrivateReferralsPage />}
              path="/privatereferrals"
            />
            <Route element={<CreateCourtReport />} path="/courtreport" />
            <Route element={<CreateNote />} path="/createnote" />
            <Route element={<UpdateNote />} path="/updatenote" />
            <Route element={<CreateNewDar />} path="/newdar" />
            <Route element={<EditDar />} path="/editdar" />
            <Route element={<EditOrderRequest />} path="/editorderrequest" />
            <Route element={<MyDar />} path="/mydar" />
            <Route element={<ExportActivities />} path="/exportactivity" />
            <Route element={<SharedDoc />} path="/shareddoc" />
            <Route element={<ClientForm />} path="/clientforms" />
            <Route element={<RequestSuppOrder />} path="/supplementalOrder" />
            <Route element={<OrderRequest />} path="/orderrequest" />
            <Route element={<ManageRoles />} path="/manageroles" />
            <Route element={<Permissions />} path="/permissions" />
            <Route element={<Audit />} path="/audit" />
            <Route element={<Profile />} path="/profile" />
          </Route>
          <Route element={<Navigate to={"/login"} />} path="/" />
          <Route element={<Login />} path="/login" />
          <Route element={<Signup />} path="/signup" />
          <Route element={<ForgotPassword />} path="/forgotpassword" />
          <Route element={<NotFound />} path="*" />
        </Routes>
      </HashRouter>
    </React.Suspense>
  );
};

export default MainRoutes;
