import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";

const PrivateRoutes = () => {
  const token = localStorage.getItem("token");

  if (token === null || token === undefined) {
    return <div>Loading...</div>; // Prevent immediate redirect
  }
  const isSignedIn = !!token;

  return isSignedIn ? (
    <>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoutes;
