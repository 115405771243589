export const courtDropdown = [
  { value: "Unspecified", label: "Unspecified" },
  { value: "MFC", label: "MFC" },
  { value: "MSC", label: "MSC" },
  { value: "BkFC", label: "BkFC" },
  { value: "BkSC", label: "BkSC" },
  { value: "BxFC", label: "BxFC" },
  { value: "BxSC", label: "BxSC" },
  { value: "QFC", label: "QFC" },
  { value: "QSC", label: "QSC" },
];

export const judgeDropdown = [
  { value: "Unknown", label: "Unknown" },
  { value: "Adams, Jody", label: "Adams, Jody" },
  { value: "Adams, Rachel", label: "Adams, Rachel" },
  { value: "Ref. Barnett, Elizabeth", label: "Ref. Barnett, Elizabeth" },
  { value: "Ref. Burnett, Marva", label: "Ref. Burnett, Marva" },
  { value: "Ref. Cook, Ivy", label: "Ref. Cook, Ivy" },
  { value: "Ref. Constanzo, Diane", label: "Ref. Constanzo, Diane" },
  { value: "Danoff, Susan", label: "Danoff, Susan" },
  { value: "Dawson, Tandra", label: "Dawson, Tandra" },
  { value: "Drager, Laura", label: "Drager, Laura" },
  { value: "Gesmer, Ellen", label: "Gesmer, Ellen" },
  { value: "Ref. Goldstein, Carol", label: "Ref. Goldstein, Carol" },
  { value: "Grosvenor, Paul", label: "Grosvenor, Paul" },
  { value: "Hoffman, Douglas", label: "Hoffman, Douglas" },
  { value: "Rodriguez, Julia", label: "Rodriguez, Julia" },
  { value: "Kaplan, Deborah", label: "Kaplan, Deborah" },
  { value: "Keisel, Diane", label: "Keisel, Diane" },
  { value: "Knipps, Susan", label: "Knipps, Susan" },
  { value: "Levy, Sue", label: "Levy, Sue" },
  { value: "Martinez-Perez, Myrna", label: "Martinez-Perez, Myrna" },
  { value: "Masley, Andrea", label: "Masley, Andrea" },
  { value: "Morgenstern, Esther", label: "Morgenstern, Esther" },
  { value: "Richardson, Clark", label: "Richardson, Clark" },
  { value: "Ref. Rodriguez, Fiordaliza", label: "Ref. Rodriguez, Fiordaliza" },
  { value: "Sattler, Lori", label: "Sattler, Lori" },
  { value: "Sosa-Litner, Gloria", label: "Sosa-Litner, Gloria" },
  { value: "Katz, Michael", label: "Katz, Michael" },
];

export const statusData = [
  {
    value: "Open",
    label: "Open",
  },
  {
    value: "Closed",
    label: "Close",
  },
  {
    value: "Suspended",
    label: "Suspended",
  },
];

export const appearanceReport = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
  {
    value: "Unsure",
    label: "Unsure",
  },
];

export const caseTypeData = [
  {
    value: "Public",
    label: "Public",
  },
  {
    value: "Private",
    label: "Private",
  },
  {
    value: "Pub/Pvt",
    label: "Pub/Pvt",
  },
];

export const serviceLocationData = [
  {
    value: "Bronx",
    label: "Bronx",
  },
  {
    value: "Brooklyn",
    label: "Brooklyn",
  },
  {
    value: "Harlem",
    label: "Harlem",
  },
  {
    value: "Manhattan",
    label: "Manhattan",
  },
  {
    value: "Queens",
    label: "Queens",
  },
  {
    value: "Staten Island",
    label: "Staten Island",
  },
  {
    value: "Other",
    label: "Other",
  },
];
